const storageTypeOptions = [
  { key: "F", name: "耗材 包材", value: "F" },
  { key: "JY1", name: "即期倉", value: "JY1" },
  { key: "JY2", name: "良品倉", value: "JY2" },
  { key: "JY3", name: "經銷倉", value: "JY3" },
  { key: "JY4", name: "線上倉", value: "JY4" },
  { key: "JY5", name: "家樂福倉", value: "JY5" },
  { key: "JY6", name: "大潤發倉", value: "JY6" },
  { key: "JY7", name: "迪卡儂倉", value: "JY7" },
  { key: "JY8", name: "寶雅倉", value: "JY8" },
  { key: "JY9", name: "全聯倉", value: "JY9" },
  { key: "JY10", name: "跨境倉", value: "JY10" },
  { key: "JY11", name: "UrMart 倉", value: "JY11" },
  { key: "JY12", name: "壞品倉", value: "JY12" },
];
export default storageTypeOptions;
