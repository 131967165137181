/* eslint-disable camelcase */

export enum PurchaseStatus {
  NORMAL = 1,
  INVALID = 2,
  DESTROYED = 3,
}

export enum Currency {
  NTD = 1,
  USD = 2,
  EUR = 3,
  GBP = 4,
  AUD = 5,
  JPY = 6,
  KRW = 7,
}

export const CurrencyMap = {
  [Currency.NTD]: "台幣",
  [Currency.USD]: "美金",
  [Currency.EUR]: "歐元",
  [Currency.GBP]: "英鎊",
  [Currency.AUD]: "澳幣",
  [Currency.JPY]: "日圓",
  [Currency.KRW]: "韓圓",
};

export enum VerifyDescription {
  PENDING_ACCEPTANCE = "待驗收",
  ACCEPTING = "驗收中",
  ACCEPTANCE_COMPLETED = "驗收完成",
  ON_SHELVES = "上架中",
  IT_HAS_BEEN_ADDED_TO = "已上架",
  ABNORMAL_WAREHOUSE_ENTRY = "入倉異常",
  WAREHOUSING_COMPLETED = "入倉完成",
  CANCEL_WAREHOUSING = "取消入倉",
}

export enum VerifyDescriptionEN {
  PENDING_ACCEPTANCE = "pendingacceptance",
  ACCEPTING = "accepting",
  ACCEPTANCE_COMPLETED = "acceptancecompleted",
  ON_SHELVES = "onshelves",
  IT_HAS_BEEN_ADDED_TO = "ithasbeenaddedto",
  ABNORMAL_WAREHOUSE_ENTRY = "abnormalwarehouseentry",
  WAREHOUSING_COMPLETED = "warehousingcompleted",
  CANCEL_WAREHOUSING = "cancelwarehousing",
}

export enum ContractMode {
  M_DOMESTIC_BUYOUT = 1,
  M_FOREIGN_BUYOUT = 2,
  M_CONSIGNMENT = 3,
  M_TRANSFER = 4,
}

export interface PurchaseList {
  id: number;
  stoNumber: string;
  stockinDate: string;
  destroyedAt: string;
  tariffNumber: string;
  totalArrivedQty: number;
  totalRequestQty: number;
  customsClearance: number;
  specDate: string;
  vendor: Vendor;
}

export interface Vendor {
  id: number | null;
  name: string | null;
  owner: {
    id: string | null;
    name: string | null;
  };
}

export interface Purchase {
  id: number;
  stoNumber: string;
  status: PurchaseStatus;
  currency: Currency;
  matoLink: string;
  staff: {
    id: number;
    name: string;
  };
  stockinDate: string;
  destroyedAt: string;
  specDate: string;
  specPeriod: number;
  applicationDate: string;
  vendor: {
    id: number;
    name: string;
    owner: {
      id: number;
      name: string;
    };
  };
}

export interface ContractVendors {
  id: number;
  name: string;
  ownerId: number;
  ownerName: string;
}

export interface PurchaseLines {
  id: number;
  purchase: number;
  productName?: string;
  sku: string;
  vpcId?: number;
  vpcPrice?: number;
  contractMode?: string;
  commissionRate: number;
  purchasePrice: number;
  requestQty: number;
  arrivedQty: number;
  goodQty: number;
  badQty: number;
  verifyDescription: VerifyDescription | string;
  stoNumber: string | null;
  rebate: number;
  tariffRate: number | null;
  processingFee: number;
  customsClearanceFee: number | null;
  canAcceptExpirationDate: string | null;
  expiredDate: string | null;
  stockinDate: string | null;
  storageType?: string;
  batch?: string;
  currencyRate: number;
  weightedCost: number;
}

export interface PurchaseVpc {
  id: number;
  price: number;
  cost: number;
  commissionRate: number;
  productName: string;
  contractId: number;
  contractMode: ContractMode;
  contractName: string;
  shelfLife: number;
}

interface NormalizeWarehouse {
  contractVendors: (
    response: {
      id: number;
      name: string;
      owner: {
        id: number;
        name: string;
      };
    }[],
  ) => ContractVendors[];
  purchaseLines: (
    response: {
      id: number;
      purchase: number;
      vpc_info?: {
        id: number;
        product_name: string;
        sku: string;
        contract_mode: string;
        price: number;
        commission_rate: number | null;
      };
      storage_type?: string;
      batch?: string;
      product?: {
        cost: number;
        id: number;
        name: string;
        no: string;
        sku: string;
      };
      purchase_price: number;
      request_qty: number;
      arrived_qty: number;
      good_qty: number;
      bad_qty: number;
      verify_description: VerifyDescription | null;
      sto_number: string | null;
      rebate: number;
      tariff_rate: number | null;
      processing_fee: number;
      customs_clearance_fee: number | null;
      can_accept_expiration_date: string | null;
      expired_date: string | null;
      stockin_date: string | null;
      currency_rate: number;
      weighted_cost: number;
    }[],
  ) => PurchaseLines[];
  purchaseVpc: (response: {
    id: number;
    price: number;
    cost: number;
    commission_rate: number | null;
    product_name: string;
    contract_info: {
      id: number;
      mode: ContractMode;
      mode_name: string;
    };
    shelf_life: number;
  }) => PurchaseVpc;
  purchaseLine: (response: {
    id: number;
    purchase: number;
    vpc_info: {
      id: number;
      product_name: string;
      sku: string;
      contract_mode: string;
      price: number;
      commission_rate: number | null;
    };
    purchase_price: number;
    request_qty: number;
    arrived_qty: number;
    good_qty: number;
    bad_qty: number;
    verify_description: VerifyDescription | null;
    sto_number: string | null;
    rebate: number;
    tariff_rate: number | null;
    processing_fee: number;
    customs_clearance_fee: number | null;
    can_accept_expiration_date: string | null;
    expired_date: string | null;
    stockin_date: string | null;
    currencyRate: number;
    weightedCost: number;
  }) => PurchaseLines;
}

const normalizeWarehouse: NormalizeWarehouse = {
  contractVendors: (response) => {
    return response.map((item) => ({
      id: item.id,
      name: item.name,
      ownerId: item.owner.id,
      ownerName: item.owner.name,
    }));
  },
  purchaseLines: (response) => {
    return response.map((item) => ({
      id: item.id,
      no: item.product && item.product.no,
      purchase: item.purchase,
      productName: (item.vpc_info && item.vpc_info.product_name) || (item.product && item.product.name),
      storageType: item.storage_type || undefined,
      batch: item.batch || undefined,
      sku: (item.vpc_info && item.vpc_info.sku) || (item.product && item.product.sku) || "",
      contractMode: item.vpc_info && item.vpc_info.contract_mode,
      vpcId: (item.vpc_info && item.vpc_info.id) || (item.product && item.product.id),
      vpcPrice: item.vpc_info && item.vpc_info.price,
      commissionRate: (item.vpc_info && item.vpc_info.commission_rate) || 0,
      purchasePrice: item.purchase_price,
      requestQty: item.request_qty,
      arrivedQty: item.arrived_qty,
      goodQty: item.good_qty,
      badQty: item.bad_qty,
      verifyDescription: item.verify_description || "",
      stoNumber: item.sto_number || "",
      rebate: item.rebate,
      tariffRate: item.tariff_rate,
      processingFee: item.processing_fee,
      customsClearanceFee: item.customs_clearance_fee,
      canAcceptExpirationDate: item.can_accept_expiration_date || null,
      expiredDate: item.expired_date || "",
      stockinDate: item.stockin_date || "",
      currencyRate: item.currency_rate,
      weightedCost: item.weighted_cost,
    }));
  },
  purchaseVpc: (response) => ({
    cost: response.cost,
    id: response.id,
    price: response.price,
    commissionRate: response.commission_rate || 100,
    productName: response.product_name,
    contractId: response.contract_info.id,
    contractMode: response.contract_info.mode,
    contractName: response.contract_info.mode_name,
    shelfLife: response.shelf_life,
  }),
  purchaseLine: (response) => ({
    id: response.id,
    purchase: response.purchase,
    productName: response.vpc_info.product_name,
    sku: response.vpc_info.sku,
    contractMode: response.vpc_info.contract_mode,
    vpcId: response.vpc_info.id,
    vpcPrice: response.vpc_info.price,
    commissionRate: response.vpc_info.commission_rate || 0,
    purchasePrice: response.purchase_price,
    requestQty: response.request_qty,
    arrivedQty: response.arrived_qty,
    goodQty: response.good_qty,
    badQty: response.bad_qty,
    verifyDescription: response.verify_description || "",
    stoNumber: response.sto_number || "",
    rebate: response.rebate,
    tariffRate: response.tariff_rate,
    processingFee: response.processing_fee,
    customsClearanceFee: response.customs_clearance_fee,
    canAcceptExpirationDate: response.can_accept_expiration_date || null,
    expiredDate: response.expired_date || "",
    stockinDate: response.stockin_date || "",
    currencyRate: response.currencyRate,
    weightedCost: response.weightedCost,
  }),
};

export default normalizeWarehouse;
