import { FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { ApprovalStatus, CustomsClearances } from "@api/utils/normalizeCustomsClearance";
import {
  clearCustomsClearanceFilter,
  fetchCustomsClearanceList,
  fetchDeleteCustomsClearance,
  setCustomsClearanceFilter,
} from "@redux/customsClearanceSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import CustomsClearanceForm from "./CustomsClearanceEdit/CustomsClearanceForm";
import CustomsClearanceFilter from "./CustomsClearanceFilter";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 28px 22px 22px;
  margin-top: 20px;
`;

const customsClearanceSlice = (state: RootState) => state.customsClearanceSlice;

export default function CustomsClearance() {
  const dispatch = useDispatch();
  const { isFetching, customsClearanceResult, customsClearanceFilter, listRefresh } = useSelector(
    customsClearanceSlice,
  );

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [initialize, setInitialize] = useState<boolean>(false);

  const setPageLimit = (value: SelectValue) => {
    dispatch(setCustomsClearanceFilter({ ...customsClearanceFilter, limit: parseInt(value as string, 10) }));
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(fetchCustomsClearanceList({ ...customsClearanceFilter, page: value }));
  };

  const columns: ColumnsType<CustomsClearances> = [
    {
      key: "tariffNumber",
      dataIndex: "tariffNumber",
      title: "進口報關單號",
      width: 166,
    },
    {
      key: "vendorName",
      dataIndex: "vendorName",
      title: "廠商名稱",
      width: 230,
      ellipsis: true,
    },
    {
      key: "tariff",
      dataIndex: "tariff",
      title: "關稅",
    },
    {
      key: "processingFee",
      dataIndex: "processingFee",
      title: "處理費",
    },
    {
      key: "shippingFee",
      dataIndex: "shippingFee",
      title: "運費",
    },
    {
      key: "transportModeText",
      dataIndex: "transportModeText",
      title: "運送方式",
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      fixed: "right",
      render: (_: any, others: CustomsClearances) => (
        <Row justify="end" align="middle" wrap={false}>
          <Button type="link" onClick={() => window.open(`${window.location.href}/${others.id}`)}>
            編輯
          </Button>
          <Button
            type="link"
            disabled={others.approvalStatus === ApprovalStatus.FINISH || others.isCostRecalculated}
            onClick={() => dispatch(fetchDeleteCustomsClearance({ id: others.id, page }))}
          >
            刪除
          </Button>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    if (!initialize) {
      dispatch(clearCustomsClearanceFilter()); // 換頁再回來要手動清
      setInitialize(true);
    } else {
      setPage(1);
      dispatch(fetchCustomsClearanceList({ ...customsClearanceFilter, page: 1 }));
    }
  }, [dispatch, customsClearanceFilter, initialize]);

  useEffect(() => {
    if (listRefresh) {
      setPage(1);
      dispatch(fetchCustomsClearanceList({ ...customsClearanceFilter, page: 1 }));
    }
  }, [listRefresh, customsClearanceFilter, dispatch]);

  return (
    <Wrapper>
      <PageTitle title="報關" />
      <Row justify="space-between">
        <div>
          <CustomButton
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            onClick={() => setOpenForm(true)}
          >
            新增報關單
          </CustomButton>
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
        </div>
        <Row align="middle">
          <PageText>{`總共${customsClearanceResult.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageLimit(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <CustomsClearanceFilter isVisible={showFilter} />
      <TableWrapper>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={customsClearanceResult.results}
          pagination={{
            pageSize: customsClearanceFilter.limit,
            current: page,
            showSizeChanger: false,
            total: customsClearanceResult.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
      {openForm && <CustomsClearanceForm close={() => setOpenForm(false)} />}
    </Wrapper>
  );
}
