import { clearCustomsClearanceFilter, setCustomsClearanceFilter } from "@redux/customsClearanceSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Col, Form, Input, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled(Form)<{ isVisible: boolean }>`
  padding: 24px 0px 0px 16px;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

type Props = {
  isVisible: boolean;
};

const customsClearanceSlice = (state: RootState) => state.customsClearanceSlice;

export default function CustomsClearanceFilter(props: Props) {
  const { isVisible } = props;

  const dispatch = useDispatch();
  const { customsClearanceFilter } = useSelector(customsClearanceSlice);

  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
    dispatch(clearCustomsClearanceFilter());
  };

  const onSubmit = (values: any) => {
    dispatch(setCustomsClearanceFilter({ ...customsClearanceFilter, ...values }));
  };

  return (
    <Wrapper
      colon={false}
      form={form}
      isVisible={isVisible}
      initialValues={{
        tariffNumber: undefined,
      }}
      onFinish={onSubmit}
    >
      <Row gutter={30}>
        <Col span={6}>
          <Form.Item label="進口報關單號" name="tariffNumber">
            <Input />
          </Form.Item>
        </Col>
        <Col span={18}>
          <Row justify="end">
            <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}
