import staffApi, { FetchStaffListParam, StaffListResult } from "@api/staffApi";
import throughShipmentApi, {
  AccessPointListPayload,
  AccessPointListResult,
  ApprovalExternalOrder,
  BatchUpdateOrderDeductions,
  BatchUpdateOrderLines,
  ContactInfoListFilter,
  ContactInfoListResult,
  CreateExternalOrderPayload,
  CreateOrderFeePayload,
  DealerFilter,
  DealerListResult,
  DeleteOrderFeePayload,
  ExternalOrderInfo,
  ExternalOrdersFilterParam,
  ExternalOrdersListResult,
  OrderDeductionsListResult,
  OrderFeeListResult,
  OrderLinesListResult,
  OrderLinesParams,
  RegionListResult,
  RegionType,
  RetailListResult,
  RetailPayload,
  RetailRegionPayload,
  UpdateExternalOrderPayload,
  UpdateOrderFeePayload,
} from "@api/throughShipment/throughShipmentApi";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { hideLoading, showLoading } from "@redux/notifySlice";
import type { RootState } from "./rootReducer";

interface IState {
  isFetching: boolean;
  isFetchingStep1Done: boolean;
  isEditExternalOrderSuccess: boolean;
  isEditOrderListSuccess: boolean;
  isEditOrderFeeSuccess: boolean;
  isEditOrderSendNotify: boolean;
  retailRegion: RegionListResult;
  retailList: RetailListResult;
  dealerList: DealerListResult;
  contactInfoList: ContactInfoListResult;
  accessPointList: AccessPointListResult;
  orderLinesList: OrderLinesListResult;
  orderDeductionsList: OrderDeductionsListResult;
  orderFeeList: OrderFeeListResult;
  externalOrderInfo?: ExternalOrderInfo;
  createExternalOrderId?: number;
  combineFetchExternalOrderData: {
    retailerName?: number;
    retailerRegion?: number;
    retailerRegionType?: RegionType;
    retailerWarehouseName?: number;
    retailerProductlineName?: number[];
  };
  orderLinesFilter: OrderLinesParams;
  externalOrdersListResult: ExternalOrdersListResult;
  externalOrdersFilter: ExternalOrdersFilterParam;
  staffListParam: FetchStaffListParam;
  staffListResult: StaffListResult;
  showImportFilePopup: boolean;
  showErrorResult: string[];
}

const initialState: IState = {
  isFetching: false,
  isFetchingStep1Done: false,
  isEditExternalOrderSuccess: false,
  isEditOrderListSuccess: false,
  isEditOrderFeeSuccess: false,
  isEditOrderSendNotify: false,
  retailRegion: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  retailList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  dealerList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  contactInfoList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  accessPointList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  orderLinesList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  orderDeductionsList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  orderFeeList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  externalOrderInfo: undefined,
  createExternalOrderId: undefined,
  combineFetchExternalOrderData: {
    retailerName: undefined,
    retailerRegion: undefined,
    retailerRegionType: undefined,
    retailerWarehouseName: undefined,
  },
  orderLinesFilter: {
    externalOrder: -1,
    sku: undefined,
    nameQ: undefined,
    nameEngQ: undefined,
    effectiveDateBefore: undefined,
    effectiveDateAfter: undefined,
  },
  externalOrdersListResult: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  externalOrdersFilter: {
    orderNumber: undefined,
    retailerNameQ: undefined,
    shippingStatus: undefined,
    staff: undefined,
    approvalStatus: undefined,
    createdAtAfter: undefined,
    createdAtBefore: undefined,
    shippingAtAfter: undefined,
    shippingAtBefore: undefined,
    limit: 20,
    offset: 0,
  },
  staffListParam: {
    nameQ: "",
    limit: 50,
    offset: 0,
    staffGroupPermission: "",
  },
  staffListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  showImportFilePopup: false,
  showErrorResult: [],
};

export const fetchRetailRegion = createAsyncThunk(
  "externalOrder/fetchRetailRegion",
  async (payload: RetailRegionPayload) => {
    const response = await throughShipmentApi.fetchRetailRegion(payload);
    return response;
  },
);

export const fetchRetailList = createAsyncThunk("externalOrder/fetchRetailList", async (payload: RetailPayload) => {
  const response = await throughShipmentApi.fetchRetailList(payload);
  return response;
});

export const fetchDealerList = createAsyncThunk("externalOrder/fetchDealerList", async (payload: DealerFilter) => {
  const response = await throughShipmentApi.fetchDealerList(payload);
  return response;
});

export const fetchContactInfoList = createAsyncThunk(
  "externalOrder/fetchContactInfoList",
  async (payload: ContactInfoListFilter) => {
    const response = await throughShipmentApi.fetchContactInfoList(payload);
    return response;
  },
);

export const fetchAccessPointList = createAsyncThunk(
  "externalOrder/fetchAccessPointList",
  async (payload: AccessPointListPayload) => {
    const response = await throughShipmentApi.fetchAccessPointList(payload);
    return response;
  },
);

export const fetchCombineExternalOrderData = createAsyncThunk(
  "externalOrder/fetchCombineExternalOrderData",
  async (externalOrder: ExternalOrderInfo, thunkApi) => {
    try {
      // 地區
      const retailRegionResponse = await thunkApi.dispatch(
        fetchRetailRegion({
          regionType: externalOrder.retailerRegionType.includes("本地通路") ? RegionType.DOMESTIC : RegionType.FOREIGN,
        }),
      );

      // 通路名稱
      const retailRegion = (retailRegionResponse.payload as RegionListResult).results.find((item) =>
        item.name.includes(externalOrder.retailerRegion),
      );
      const retailListResponse = await thunkApi.dispatch(fetchRetailList({ region: retailRegion?.id }));

      // 通路線別
      const retail = (retailListResponse.payload as RetailListResult).results.find((item) =>
        item.name.includes(externalOrder.retailerName),
      );
      const dealerListResponse = await thunkApi.dispatch(
        fetchDealerList({ retailer: retail?.id, limit: 100, offset: 0 }),
      );

      // 通路出貨倉點
      const map = new Map<string, number>();
      (dealerListResponse.payload as DealerListResult).results.forEach((item) => {
        map.set(item.productLine.name, item.productLine.id);
      });
      const list: number[] = [];
      externalOrder.retailerProductlineName.forEach((item) => {
        if (map.has(item.toString())) {
          list.push(map.get(item.toString()) as number);
        }
      });
      const accessPointList = await thunkApi.dispatch(
        fetchAccessPointList({ retailer: retail?.id, productlines: list.toString(), limit: 100, offset: 0 }),
      );
      const accessPoint = (accessPointList.payload as AccessPointListResult).results.find((item) =>
        item.name.includes(externalOrder.retailerWarehouseName),
      );

      return {
        retailerName: retail?.id,
        retailerRegion: retailRegion?.id,
        retailerRegionType: externalOrder.retailerRegionType.includes("本地通路")
          ? RegionType.DOMESTIC
          : RegionType.FOREIGN,
        retailerWarehouse: accessPoint?.id,
        retailerProductlineName: list,
      };
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchExternalOrdersList = createAsyncThunk(
  "externalOrder/fetchExternalOrdersList",
  async (payload: ExternalOrdersFilterParam) => {
    const response = throughShipmentApi.fetchExternalOrdersList(payload);
    return response;
  },
);

export const fetchExternalOrderInfo = createAsyncThunk(
  "externalOrder/fetchExternalOrderInfo",
  async (externalOrderId: number) => {
    const response = await throughShipmentApi.fetchExternalOrderInfo(externalOrderId);
    return response;
  },
);

export const fetchCreateExternalOrder = createAsyncThunk(
  "externalOrder/fetchCreateExternalOrder",
  async (payload: CreateExternalOrderPayload, thunkApi) => {
    try {
      const response = await throughShipmentApi.fetchCreateExternalOrder(payload);
      await thunkApi.dispatch(fetchExternalOrderInfo(response.id));
      message.success("新增成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateExternalOrder = createAsyncThunk(
  "externalOrder/fetchUpdateExternalOrder",
  async (payload: UpdateExternalOrderPayload & { externalOrderId: number }, thunkApi) => {
    try {
      const { externalOrderId, ...otherPayload } = payload;
      await throughShipmentApi.fetchUpdateExternalOrder(externalOrderId, otherPayload);
      message.success("更新成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchDeleteExternalOrder = createAsyncThunk(
  "externalOrder/fetchDeleteExternalOrder",
  async (externalOrderId: number, thunkApi) => {
    try {
      await throughShipmentApi.fetchDeleteExternalOrder(externalOrderId);
      message.success("作廢成功");
      await thunkApi.dispatch(fetchExternalOrderInfo(externalOrderId));
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchApprovalExternalOrder = createAsyncThunk(
  "externalOrder/fetchApprovalExternalOrder",
  async (payload: ApprovalExternalOrder & { externalOrderId: number }, thunkApi) => {
    try {
      const { externalOrderId, ...otherPayload } = payload;
      await throughShipmentApi.fetchApprovalExternalOrder(externalOrderId, otherPayload);
      await thunkApi.dispatch(fetchExternalOrderInfo(externalOrderId));
      message.success("審核成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchOrderLines = createAsyncThunk("externalOrder/fetchOrderLines", async (params: OrderLinesParams) => {
  const response = await throughShipmentApi.fetchOrderLines(params);
  return response;
});
export const fetchCreateProduct = createAsyncThunk(
  "externalOrder/fetchCreatProduct",
  async (params: { externalOrder: string; no: string }, thunkApi) => {
    try {
      const { externalOrder } = params;
      const response = await throughShipmentApi.fetchCreateProduct(params);
      thunkApi.dispatch(fetchOrderLines({ externalOrder }));
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const fetchDeleteProduct = createAsyncThunk(
  "externalOrder/fetchDeleteProduct",
  async (params: { externalOrder: string; id: number }, thunkApi) => {
    try {
      const { externalOrder } = params;
      await throughShipmentApi.fetchDeleteProduct(params);
      message.success("刪除成功");
      thunkApi.dispatch(fetchOrderLines({ externalOrder }));
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const importIWappsProductsXLS = createAsyncThunk(
  "intoWarehouseBrandSlice/importIWappsProductsXLS",
  async (payload: { externalOrder: string; file: File }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const { externalOrder, file } = payload;
      const response = await throughShipmentApi.importIWappsProductsXLS({ externalOrder, file });
      message.success("匯入成功");
      thunkApi.dispatch(fetchOrderLines({ externalOrder }));
      return response;
    } catch (error: any) {
      const errorResults = Object.values(error.result);
      errorResults.forEach((errorResult: any) => {
        return message.error(errorResult);
      });
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);

export const fetchBatchUpdateOrderLines = createAsyncThunk(
  "externalOrder/fetchBatchUpdateOrderLines",
  async (payload: BatchUpdateOrderLines[], thunkApi) => {
    try {
      await throughShipmentApi.fetchBatchUpdateOrderLines(payload);
      message.success("更新成功");
      thunkApi.dispatch(setShowErrorResult([]));
      return "Success";
    } catch (error: any) {
      if (error.result) {
        thunkApi.dispatch(setShowErrorResult(error.result));
      }
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const fetchSingleUpdateOrderLines = createAsyncThunk(
  "externalOrder/fetchSingleUpdateOrderLines",
  async (payload: BatchUpdateOrderLines[], thunkApi) => {
    try {
      await throughShipmentApi.fetchBatchUpdateOrderLines(payload);
      return "Success";
    } catch (error: any) {
      if (error.result) {
        thunkApi.dispatch(setShowErrorResult(error.result));
      }
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchOrderDeductions = createAsyncThunk(
  "externalOrder/fetchOrderDeductions",
  async (externalOrderId: number) => {
    const response = await throughShipmentApi.fetchOrderDeductions(externalOrderId);
    return response;
  },
);

export const fetchBatchUpdateOrderDeductions = createAsyncThunk(
  "externalOrder/fetchBatchUpdateOrderDeductions",
  async (payload: BatchUpdateOrderDeductions[], thunkApi) => {
    try {
      await throughShipmentApi.fetchBatchUpdateOrderDeductions(payload);
      message.success("更新成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchOrderFeeList = createAsyncThunk(
  "externalOrder/fetchOrderFeeList",
  async (externalOrderId: number) => {
    const response = await throughShipmentApi.fetchOrderFeeList(externalOrderId);
    return response;
  },
);

export const fetchBatchCreateOrderFee = createAsyncThunk(
  "externalOrder/fetchBatchCreateOrderFee",
  async (payload: CreateOrderFeePayload[], thunkApi) => {
    try {
      await throughShipmentApi.fetchBatchCreateOrderFee(payload);
      const { externalOrderSlice } = thunkApi.getState() as RootState;
      await thunkApi.dispatch(
        fetchOrderFeeList(externalOrderSlice.externalOrderInfo?.id || externalOrderSlice.createExternalOrderId!),
      );
      message.success("新增成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchBatchUpdateOrderFee = createAsyncThunk(
  "externalOrder/fetchBatchUpdateOrderFee",
  async (payload: UpdateOrderFeePayload[], thunkApi) => {
    try {
      await throughShipmentApi.fetchBatchUpdateOrderFee(payload);
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchBatchDeleteOrderFee = createAsyncThunk(
  "externalOrder/fetchBatchDeleteOrderFee",
  async (payload: DeleteOrderFeePayload, thunkApi) => {
    try {
      await throughShipmentApi.fetchBatchDeleteOrderFee(payload);
      const { externalOrderSlice } = thunkApi.getState() as RootState;
      await thunkApi.dispatch(fetchOrderFeeList(externalOrderSlice.externalOrderInfo!.id));
      message.success("刪除成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchSendOrderNotify = createAsyncThunk(
  "externalOrder/fetchSendOrderNotify",
  async (externalOrderId: number, thunkApi) => {
    try {
      await throughShipmentApi.fetchSendOrderNotify(externalOrderId);
      await thunkApi.dispatch(fetchExternalOrderInfo(externalOrderId));
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const fetchStaffList = createAsyncThunk("externalOrder/fetchStaffList", async (_, thunkApi) => {
  const params: FetchStaffListParam = {
    limit: 20,
    offset: 0,
    staffGroupPermission: "product_owner",
  };
  const response = await staffApi.fetchStaffList(params);
  return response;
});
export const exportCSV = createAsyncThunk("externalOrder/exportCSV", async (payload: any, thunkApi) => {
  try {
    await throughShipmentApi.exportCSV(payload);
    message.success("匯出資料準備中，成功後會寄信通知");
    return "Success";
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

const externalOrderSlice = createSlice({
  name: "externalOrder",
  initialState,
  reducers: {
    resetExternalOrderSlice: () => initialState,
    setOrderLinesFilter: (state, action) => {
      state.orderLinesFilter = action.payload;
    },
    setExternalOrdersFilter: (state, action) => {
      state.externalOrdersFilter = action.payload;
    },
    updateNowCurrency: (state, action) => {
      if (state.externalOrderInfo) {
        state.externalOrderInfo = { ...state.externalOrderInfo, currency: action.payload };
      }
    },
    setImportFilePopup: (state, action) => {
      state.showImportFilePopup = action.payload;
    },
    setShowErrorResult: (state, action) => {
      state.showErrorResult = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRetailRegion.fulfilled, (state, action) => {
      state.retailRegion = action.payload;
    });
    builder.addCase(fetchRetailList.fulfilled, (state, action) => {
      state.retailList = action.payload;
    });
    builder.addCase(fetchDealerList.fulfilled, (state, action) => {
      state.dealerList = action.payload;
    });
    builder.addCase(fetchContactInfoList.fulfilled, (state, action) => {
      state.contactInfoList = action.payload;
    });
    builder.addCase(fetchAccessPointList.fulfilled, (state, action) => {
      state.accessPointList = action.payload;
    });
    builder.addCase(fetchExternalOrdersList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchExternalOrdersList.fulfilled, (state, action) => {
      state.externalOrdersListResult = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchExternalOrderInfo.fulfilled, (state, action) => {
      state.createExternalOrderId = undefined;
      state.externalOrderInfo = action.payload;
      state.isEditExternalOrderSuccess = false;
      state.isEditOrderListSuccess = false;
      state.isEditOrderFeeSuccess = false;
    });
    builder.addCase(fetchCreateExternalOrder.fulfilled, (state, action) => {
      state.createExternalOrderId = action.payload.id;
      state.isEditExternalOrderSuccess = true;
    });
    builder.addCase(fetchUpdateExternalOrder.fulfilled, (state) => {
      state.isEditExternalOrderSuccess = true;
    });
    builder.addCase(fetchCombineExternalOrderData.fulfilled, (state, action) => {
      state.combineFetchExternalOrderData = action.payload;
      state.isFetchingStep1Done = true;
    });
    builder.addCase(fetchOrderLines.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchOrderLines.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isEditExternalOrderSuccess = false;
      state.isEditOrderListSuccess = false;
      state.isEditOrderFeeSuccess = false;
      state.orderLinesList = action.payload;
    });
    builder.addCase(fetchBatchUpdateOrderLines.fulfilled, (state) => {
      state.isEditOrderListSuccess = true;
    });
    builder.addCase(fetchOrderDeductions.fulfilled, (state, action) => {
      state.orderDeductionsList = action.payload;
      state.isEditExternalOrderSuccess = false;
      state.isEditOrderListSuccess = false;
      state.isEditOrderFeeSuccess = false;
    });
    builder.addCase(fetchOrderFeeList.fulfilled, (state, action) => {
      state.orderFeeList = action.payload;
      state.isEditOrderFeeSuccess = false;
    });
    builder.addCase(fetchBatchUpdateOrderDeductions.fulfilled, (state) => {
      state.isEditOrderFeeSuccess = true;
    });
    builder.addCase(fetchBatchUpdateOrderFee.fulfilled, (state) => {
      state.isEditOrderFeeSuccess = true;
    });
    builder.addCase(fetchSendOrderNotify.fulfilled, (state) => {
      state.isEditOrderSendNotify = true;
    });
    builder.addCase(fetchStaffList.fulfilled, (state, action) => {
      state.staffListResult = action.payload;
    });
    builder.addCase(importIWappsProductsXLS.fulfilled, (state, action) => {
      state.showImportFilePopup = false;
    });
  },
});

export const {
  resetExternalOrderSlice,
  setOrderLinesFilter,
  setExternalOrdersFilter,
  setImportFilePopup,
  updateNowCurrency,
  setShowErrorResult,
} = externalOrderSlice.actions;
export const externalOrderState = (state: RootState) => state.externalOrderSlice;
export default externalOrderSlice.reducer;
