import { combineReducers } from "@reduxjs/toolkit";
import attributeSlice from "./attributeSlice";
import auth from "./authSlice";
import bank from "./bankSlice";
import brandPageSlice from "./brandPageSlice";
import brand from "./brandSlice";
import cohortSlice from "./cohortSlice";
import configSetting from "./configSettingSlice";
import config from "./configSlice";
import contract from "./contractSlice";
import customsClearanceSlice from "./customsClearanceSlice";
import dashboard from "./dashboardSlice";
import dealerSlice from "./dealerSlice";
import externalOrderSlice from "./externalOrderSlice";
import onlineOrderSlice from "./onlineOrderSlice";
import member from "./memberSlice";
import orderSlice from "./orderSlice";
import productCategory from "./productCategorySlice";
import productEdit from "./productEditSlice";
import product from "./productSlice";
import promotionAddRule from "./promotionAddRuleSlice";
import promotionAddSales from "./promotionAddSalesSlice";
import promotion from "./promotionSlice";
import returnOrderSlice from "./returnOrderSlice";
import reviewManagement from "./reviewManagementSlice";
import ruleAddPromotion from "./ruleAddPromotionSlice";
import ruleAddSalePlan from "./ruleAddSalePlanSlice";
import rule from "./ruleSlice";
import salesCategoryAddSales from "./salesCategoryAddSalesSlice";
import salesCategory from "./salesCategorySlice";
import saleSlice from "./saleSlice";
import salesReport from "./salesReportSlice";
import shippingFee from "./shippingFeeSlice";
import slot from "./slotSlice";
import staffs from "./staffSlice";
import stock from "./stockSlice";
import supplyChain from "./supplyChainSlice";
import topic from "./topicSlice";
import userSlice from "./userSlice";
import vendorSlice from "./vendorSlice";
import vipPromotionSlice from "./vipPromotionSlice";
import vipRuleSlice from "./vipRuleSlice";
import vipStatisticsSlice from "./vipStatisticsSlice";
import warehouseSlice from "./warehouseSlice";
import discountFrameSlice from "./discountFrameSlice";
import slotAddSalesSlice from "./slotAddSalesSlice";
import menuMangement from "./menuManagementSlice";
import notifySlice from "./notifySlice";
// 共用
import specialShipmentSlice from "./specialShipmentSlice";
// 財務後台
import cooperationSlice from "./finance/cooperationSlice";
import invoiceSlice from "./finance/invoiceSlice";
import costStatementSlice from "./finance/costStatementSlice";
import returnListPageSlice from "./finance/returnListPageSlice";
import excessInventorySlice from "./finance/excessInventorySlice";
import scrapInventorySlice from "./finance/scrapInventorySlice";
import ODStatisticsSlice from "./finance/onlineDistributorStatisticsSlice";
import statisticsDownloadSlice from "./finance/statisticsDownloadSlice";
// 品運後台
import brandOperationProductSlice from "./brandOperation/productSlice";
import BOStockSlice from "./brandOperation/BOstockSlice"; // 庫存
import intoWarehouseBrandSlice from "./brandOperation/intoWarehouseBrandSlice"; // 進倉
import manufacturerSlice from "./brandOperation/manufacturerSlice"; // 供應商
import pcmSlice from "./brandOperation/PcmSlice";
import recostSlice from "./recostSlice";
import nutritionSlice from "./nutritionSlice"; // 營養標示
import ProcessingApplicationSlice from "./ProcessingApplicationSlice"; // 加工單
import tryallStockManagementSlice from "./finance/tryallStockManagementSlice";

const rootReducer = combineReducers({
  auth,
  topic,
  brand,
  productCategory,
  salesCategory,
  salesCategoryAddSales,
  product,
  contract,
  productEdit,
  slot,
  slotAddSalesSlice,
  staffs,
  bank,
  stock,
  promotion,
  promotionAddSales,
  promotionAddRule,
  rule,
  ruleAddPromotion,
  ruleAddSalePlan,
  warehouseSlice,
  customsClearanceSlice,
  orderSlice,
  userSlice,
  saleSlice,
  vendorSlice,
  member,
  menuMangement,
  salesReport,
  dashboard,
  reviewManagement,
  vipRuleSlice,
  returnOrderSlice,
  config,
  brandPageSlice,
  vipPromotionSlice,
  shippingFee,
  configSetting,
  supplyChain,
  dealerSlice,
  externalOrderSlice,
  onlineOrderSlice,
  cohortSlice,
  vipStatisticsSlice,
  attributeSlice,
  cooperationSlice,
  invoiceSlice,
  discountFrameSlice,
  costStatementSlice,
  returnListPageSlice,
  excessInventorySlice,
  scrapInventorySlice,
  notifySlice,
  ODStatisticsSlice,
  brandOperationProductSlice,
  BOStockSlice,
  intoWarehouseBrandSlice,
  recostSlice,
  nutritionSlice,
  manufacturerSlice,
  statisticsDownloadSlice,
  ProcessingApplicationSlice,
  pcmSlice,
  tryallStockManagementSlice,
  specialShipmentSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
