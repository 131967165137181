import React, { KeyboardEvent, useEffect, useMemo, useState } from "react";
import { FilterTwoTone } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row, Select, Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FlexBox, Header, PageText, SelectPageSize } from "src/styles/common";
import {
  BOStockState,
  exportODListCSV,
  fetchBOStockList,
  resetBOStockParams,
  resetBOStockSlice,
  updateBOStockParams,
} from "@redux/brandOperation/BOstockSlice";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import { ColumnsType } from "antd/lib/table";
import PopupBackground from "@component/PopupBackground";
import DashBoard from "@component/Common/DashBoard";
import { BOStockItemDetail, BOStockListItem } from "@api/brandOperationApi";
import storageTypeOptions from "@constant/StorageType";

const Wrapper = styled.div`
  padding: 18px 14px 0px 14px;
`;
const CRow = styled.div<{ justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;
const DetailsWrapper = styled.div`
  max-width: 600px;
  min-height: 170px;
  padding: 20px;
  background-color: ${(props) => props.theme.colorNeutral100};
  position: relative;
`;
// Filter
const FilterWrapper = styled.div<{ show: boolean }>`
  height: auto;
  max-height: ${({ show }) => (show ? "600px" : 0)};
  margin: ${({ show }) => (show ? "20px 10px;" : "10px")};
  overflow: hidden;
  transition: 0.3s;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;
const CustomCol = styled(Col)`
  margin-right: 10px;
  position: absolute;
  top: -26px;
  left: 66px;
`;

const BOstockList = () => {
  const dispatch = useDispatch();
  const { isFetching, BOStockList, BOstockListParams } = useSelector(BOStockState);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [stockDetails, setStockDetails] = useState<BOStockItemDetail[] | undefined>(undefined);
  const [page, setPage] = useState<number>(1);

  const { results: stockList, count: totalCount, totalAmount, totalStock } = BOStockList;

  const dashBoardData = useMemo(
    () => [
      { name: "庫存數", value: totalStock },
      { name: "庫存價值", value: totalAmount },
    ],
    [totalStock, totalAmount],
  );

  const handleOnExport = () => {
    dispatch(exportODListCSV());
  };
  const handleOnPageSizeChange = (value: string) => {
    const params = {
      ...BOstockListParams,
      limit: Number(value),
    };
    dispatch(updateBOStockParams(params));
  };

  const handlePageChange = (value: number) => {
    setPage(value);
    dispatch(
      updateBOStockParams({
        ...BOstockListParams,
        offset: BOstockListParams.limit * (value - 1),
      }),
    );
  };
  const columns: ColumnsType<BOStockListItem> = [
    {
      key: "no",
      dataIndex: "no",
      title: "商品品號",
      width: 120,
    },
    {
      key: "sku",
      dataIndex: "sku",
      title: "商品條碼",
      width: 120,
    },
    {
      key: "productName",
      dataIndex: "productName",
      title: "商品名稱",
      width: 120,
    },
    {
      key: "storageType",
      dataIndex: "storageType",
      title: "倉別",
      width: 120,
      render: (value) => storageTypeOptions.find((type) => type.key === value)?.name || "-",
    },
    {
      key: "canSalesQty",
      dataIndex: "canSalesQty",
      title: "庫存數",
      width: 120,
      render: (value, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setStockDetails(record.details);
            }}
          >
            {value}
          </Button>
        );
      },
    },
    {
      key: "stockValue",
      dataIndex: "stockValue",
      title: "庫存價值",
      width: 120,
    },
  ];

  useEffect(() => {
    dispatch(fetchBOStockList());
  }, [dispatch, BOstockListParams]);

  useEffect(() => {
    return () => {
      dispatch(resetBOStockSlice());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <PageTitle title="品運庫存效期" />
      <Header>
        <FlexBox>
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
          <Button onClick={handleOnExport}>匯出</Button>
        </FlexBox>
        <CRow>
          <PageText>{`總共${BOStockList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => handleOnPageSizeChange(value as string)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </CRow>
      </Header>
      <Filter show={showFilter} />
      <DashBoard dataset={dashBoardData} isFetching={isFetching} />
      <Table
        loading={isFetching}
        scroll={{ x: "max-content", y: 500 }}
        columns={columns}
        dataSource={stockList}
        rowKey="id"
        pagination={{
          pageSize: BOstockListParams.limit,
          current: page,
          showSizeChanger: false,
          total: totalCount,
          onChange: handlePageChange,
        }}
      />
      {stockDetails && <StockDetailPopup closePopup={() => setStockDetails(undefined)} detailsList={stockDetails} />}
    </Wrapper>
  );
};
export default BOstockList;

const Filter = (props: { show: boolean }) => {
  const { show } = props;
  const dispatch = useDispatch();
  const { BOstockListParams } = useSelector(BOStockState);

  const [form] = Form.useForm();
  const [inputValue, setInputValue] = useState(""); // sku input
  const [tags, setTags] = useState<string[]>([]); // multiple sku tags

  const onSubmit = () => {
    const formValues = form.getFieldsValue();
    let valid = false;
    Object.keys(formValues).forEach((key) => {
      const item = formValues[key];
      if (item) valid = true;
      if (key === "skus" && inputValue) {
        valid = true;
      }
    });
    if (!valid) return;

    let skusParsed;
    const skus = form.getFieldValue("skus");
    if (inputValue && skus) skusParsed = `${inputValue},${skus}`;
    if (inputValue && !skus) skusParsed = `${inputValue}`;
    if (!inputValue) skusParsed = skus;

    const params = {
      ...BOstockListParams,
      no: form.getFieldValue("no"),
      skus: skusParsed || undefined,
      storageType: form.getFieldValue("storageType"),
      nameQ: form.getFieldValue("nameQ"),
    };
    dispatch(updateBOStockParams(params));
  };

  const onReset = () => {
    form.resetFields();
    setInputValue("");
    setTags([]);
    dispatch(resetBOStockParams());
  };
  const handleSkuInputPressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    if (tags.some((tag) => tag === value)) {
      message.warning("此商品條碼已加入");
      return;
    }
    setTags([...tags, inputValue]);
    setInputValue("");
  };

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  useEffect(() => {
    form.setFieldsValue({ skus: tags.toString() });
  }, [form, tags]);
  return (
    <FilterWrapper show={show}>
      <Form colon={false} labelAlign="left" form={form} initialValues={{}}>
        <Row gutter={[16, 8]}>
          <Col style={{ height: "31px" }} />
        </Row>
        <Row gutter={16}>
          <Col span={6} style={{ position: "relative" }}>
            <Form.Item label="商品品號" name="no">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <CustomCol flex="auto">
              {tags.map((tag) => (
                <span key={tag}>
                  <Tag
                    closable
                    onClose={(e) => {
                      e.preventDefault();
                      handleClose(tag);
                    }}
                  >
                    {tag}
                  </Tag>
                </span>
              ))}
            </CustomCol>
            <Form.Item label="商品條碼">
              <Input
                onChange={(e) => setInputValue(e.target.value)}
                onPressEnter={handleSkuInputPressEnter}
                placeholder="可用Enter加入多筆"
                value={inputValue}
                allowClear
                style={{ width: "50%" }}
              />
            </Form.Item>
            <Form.Item name="skus" noStyle />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={9}>
            <Form.Item label="商品名稱" name="nameQ">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="倉別" name="storageType">
              <Select placeholder="請選擇">
                {storageTypeOptions.map((option) => (
                  <Select.Option key={option.name} value={option.value}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <FlexBox>
        <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
        <Button type="primary" onClick={onSubmit}>
          套用
        </Button>
      </FlexBox>
    </FilterWrapper>
  );
};

type DetailProps = {
  closePopup: () => void;
  detailsList: BOStockItemDetail[];
};
const StockDetailPopup = (props: DetailProps) => {
  const { closePopup, detailsList } = props;

  const closeDetailPopup = () => {
    closePopup();
  };
  const columns: ColumnsType<BOStockItemDetail> = [
    {
      key: "effectiveDate",
      dataIndex: "effectiveDate",
      title: "有效日期",
      width: 120,
    },
    {
      key: "batch",
      dataIndex: "batch",
      title: "批號",
    },
    {
      key: "canSalesQty",
      dataIndex: "canSalesQty",
      title: "庫存數",
    },
    {
      key: "stockValue",
      dataIndex: "stockValue",
      title: "庫存價值",
    },
  ];

  return (
    <PopupBackground close={closeDetailPopup}>
      <DetailsWrapper>
        <Table
          scroll={{ x: "max-content", y: 500 }}
          columns={columns}
          dataSource={detailsList}
          rowKey="id"
          pagination={false}
        />
        <FlexBox marginTop="12px">
          <Button type="primary" onClick={closeDetailPopup}>
            關閉
          </Button>
        </FlexBox>
      </DetailsWrapper>
    </PopupBackground>
  );
};
