enum MenuListValue {
  // 主選單
  PLATFORM = "PLATFORM",
  COMMON = "COMMON",
  BRAND_OPERATION = "BRAND_OPERATION",
  FINANCE = "FINANCE",
  // --------------------
  DASHBOARD = "DASHBOARD",
  SALES_STATISTICS = "SALES_STATISTICS",
  MARKET_STATISTICS = "MARKET_STATISTICS",
  SHELVES_DISPLAY = "SHELVES_DISPLAY",
  SALES_PAGE = "SALES_PAGE",
  SALES_PAGE_CATEGORY = "SALES_PAGE_CATEGORY",
  TOPIC = "TOPIC",
  MARKETING_PROMOTION = "MARKETING_PROMOTION",
  MENU_MANAGEMENT = "MENU_MANAGEMENT",
  PROMOTION = "PROMOTION",
  VIP_RULE = "VIP_RULE",
  RULE = "RULE",
  SLOT_MANAGEMENT = "SLOT_MANAGEMENT",
  PRODUCT_MANAGEMENT = "PRODUCT_MANAGEMENT",
  PRODUCT = "PRODUCT",
  CONTRACT = "CONTRACT",
  SHELF_LIFE_MANAGEMENT = "SHELF_LIFE_MANAGEMENT",
  STOCK_MANAGEMENT = "STOCK_MANAGEMENT",
  BRAND = "BRAND",
  PRODUCT_CATEGORY = "PRODUCT_CATEGORY",
  ATTRIBUTE_MANAGEMENT = "ATTRIBUTE_MANAGEMENT",
  MEMBER = "MEMBER",
  MEMBER_DATA = "MEMBER_DATA",
  ORDER = "ORDER",
  ORDER_CONTENT = "ORDER_CONTENT",
  REPLENISH_RETURN = "REPLENISH_RETURN",
  REVIEW_MANAGEMENT = "REVIEW_MANAGEMENT",
  SYSTEM = "SYSTEM",
  TAG_MANAGEMENT = "TAG_MANAGEMENT",
  SYSTEM_MANAGEMENT = "SYSTEM_MANAGEMENT",
  VENDOR = "VENDOR",
  VENDOR_LIST = "VENDOR_LIST",
  SUPPLEMENT = "SUPPLEMENT",
  REPORT_REGEN = "REPORT_REGEN",
  VENDOR_MANAGEMENT = "VENDOR_MANAGEMENT",
  BRAND_PAGE = "BRAND_PAGE",
  VIP_PROMOTION_GIFT = "VIP_PROMOTION_GIFT",
  VIP_GIFT = "VIP_GIFT",
  VIP_BULLETIN_BOARD = "VIP_BULLETIN_BOARD",
  VIP_PROMOTION = "VIP_PROMOTION",
  SHIPPING_PATH = "SHIPPING_PATH",
  MEMBER_COHORT = "MEMBER_COHORT",
  VIP_STATISTICS = "VIP_STATISTICS",
  DISCOUNT_FRAME = "DISCOUNT_FRAME",
  // 通路出貨
  DEALER = "DEALER",
  SUPPLY_CHAIN = "SUPPLY_CHAIN",
  EXTERNAL_ORDER = "EXTERNAL_ORDER",
  ONLINE_ORDER = "ONLINE_ORDER",
  // 出入庫
  WAREHOUSE = "WAREHOUSE",
  INTO_WAREHOUSE = "INTO_WAREHOUSE",
  RETURN_INVENTORY = "RETURN_INVENTORY",
  // 共用功能
  CUSTOMS_CLEARANCE = "CUSTOMS_CLEARANCE",
  RECOST = "RECOST",
  SPECIAL_SHIPMENT = "SPECIAL_SHIPMENT",
  // 財務後台
  INVOICE = "INVOICE",
  COOPERATION = "COOPERATION",
  COST_STATEMENT = "COST_STATEMENT",
  RETURN_LIST = "RETURN_LIST",
  EXCESS_INVENTORY = "EXCESS_INVENTORY",
  SCRAP_INVENTORY = "SCRAP_INVENTORY",
  ONLINE_DISTRIBUTOR_STATISTICS = "ONLINE_DISTRIBUTOR_STATISTICS",
  TRYALL_STOCK_MANAGEMENT = "TRYALL_STOCK_MANAGEMENT",
  STATISTICS_DOWNLOAD = "STATISTICS_DOWNLOAD",
  ORDER_PAYMENT = "ORDER_PAYMENT",
  // 品運後台
  BO_PRODUCT = "BO_PRODUCT",
  BO_STOCK = "BO_STOCK",
  INTO_WAREHOUSE_BRAND = "INTO_WAREHOUSE_BRAND",
  MANUFACTURER = "MANUFACTURER",
  PROCESSING_APPLICATION = "PROCESSING_APPLICATION",
  PROCUREMENT = "PROCUREMENT",
}

export default MenuListValue;
