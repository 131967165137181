import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import styled from "styled-components";
import { Product } from "@api/throughShipment/onlineOrderApi";
import { fetchDeleteProduct, onlineOrderState } from "@redux/onlineOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import storageTypeOptions from "@constant/StorageType";

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const EditText = styled(Button)`
  padding: 0;
`;
const DeleteText = styled(Button)`
  margin-left: 23px;
  padding: 0;
`;

type Props = {
  list: Product[];
  orderId: string;
  cannotEdit: boolean;
  openProductForm: (product: Product) => void;
  pagination: {
    limit: number;
    current: number;
  };
  onChange?: (limit: any) => void;
};

export default function ProductList(props: Props) {
  const dispatch = useDispatch();
  const { isProductFetching } = useSelector(onlineOrderState);

  const { list, openProductForm, pagination, onChange, orderId, cannotEdit } = props;
  const columns: ColumnsType<Product> = [
    { title: "ID", dataIndex: "id" },
    { title: "品號", dataIndex: "sku" },
    { title: "barcode", dataIndex: "barcode" },
    { title: "商品名稱", dataIndex: "productName" },
    {
      title: "倉別",
      dataIndex: "storageType",
      render: (value) => storageTypeOptions.find((type) => type.key === value)?.name || "-",
    },
    { title: "商品效期", dataIndex: "effectiveDate" },
    { title: "批號", dataIndex: "batch" },
    {
      title: `${cannotEdit ? "實際數量" : "庫存量"}`,
      dataIndex: "stock",
      render: (_, values) => (cannotEdit ? <span>{values.actualQty}</span> : <span>{values.stock}</span>),
    },
    { title: "商品數量(pcs)", dataIndex: "estimatedQty" },
    {
      title: "",
      dataIndex: "id",
      width: 30,
      render: (productId: number, record: Product) => {
        return (
          <Flex>
            <EditText
              type="link"
              onClick={() => {
                openProductForm(record);
              }}
              disabled={cannotEdit}
            >
              編輯
            </EditText>
            <DeleteText
              disabled={cannotEdit}
              type="link"
              onClick={() => {
                Modal.confirm({
                  title: "確定要刪除此商品嗎?",
                  icon: <ExclamationCircleOutlined />,
                  okText: "是",
                  cancelText: "否",
                  onOk() {
                    dispatch(fetchDeleteProduct({ orderId, productId }));
                  },
                });
              }}
            >
              刪除
            </DeleteText>
          </Flex>
        );
      },
    },
  ];

  return (
    <Table
      loading={isProductFetching}
      columns={columns}
      scroll={{ x: "max-content" }}
      dataSource={list}
      pagination={{
        pageSize: pagination.limit,
        current: pagination.current,
        showSizeChanger: false,
        total: list.length,
        onChange,
      }}
    />
  );
}
