import { CloseOutlined } from "@ant-design/icons";
import { Select } from "antd";
import styled from "styled-components";
import fontStyle from "./fontStyle";

export const StyledSection = styled.section`
  padding: 20px 25px;
`;

export const StyledOutlinedWrapper = styled.div`
  border: 1px solid #f0f0f0;
`;

// right Content
export const ContentWrapper = styled.div`
  padding: 18px 14px 0px 14px;
`;

// popup Modal
export const ModalWrapper = styled.div<{ width?: string; height?: string }>`
  width: ${({ width }) => width || "620px"};
  height: ${({ height }) => height || "auto"};
  background-color: ${({ theme }) => theme.colorNeutral100};
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

// tab
export const TabWrapper = styled.div`
  padding: 18px 14px 0px 14px;

  .ant-tabs {
    overflow: visible;
  }
`;
export const TabContentWrapper = styled.div`
  padding: 27px 35px 60px;
`;
// Popup
export const CloseIcon = styled(CloseOutlined)<{ top?: string; right?: string; position?: string }>`
  position: ${(props) => props.position || "absolute"};
  top: ${(props) => props.top || "22px"};
  right: ${(props) => props.right || "22px"};
`;

// table
export const TableWrapper = styled.div`
  .destroyed-row {
    color: rgba(189, 189, 189, 0.65);
  }
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  > Button {
    margin-right: 10px;
  }
`;

export const FlexBox = styled.div<{ marginTop?: string }>`
  display: flex;
  justify-content: flex-end;
  ${({ marginTop }) => (marginTop ? `margin-top:${marginTop};` : "")}
  Button {
    margin-right: 10px;
  }
`;

export const CustomCenterRow = styled.div<{ justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;

// pagination
export const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

export const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

// Filter
export const FilterWrapper = styled.div<{ show: boolean }>`
  height: auto;
  max-height: ${({ show }) => (show ? "600px" : 0)};
  margin: ${({ show }) => (show ? "20px 10px;" : "10px")};
  overflow: hidden;
  transition: 0.3s;
`;

// Form

export const RowTitle = styled.div`
  align-self: flex-start;
  text-align: left;
  width: 115px;
  line-height: 32px;
  margin-right: 15px;
  font-size: 14px;
`;

export const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;
