import { ProcessType } from "@api/sharing/specialShipmentApi";
import PageTitle from "@component/PageTitle";
import {
  fetchSpecialShipmentDetail,
  handleSpecialShipmentStatus,
  setOpenShipmentFormType,
  specialShipmentState,
} from "@redux/specialShipmentSlice";
import { Button, Skeleton, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { StyledSection } from "src/styles/common";
import styled from "styled-components";
import SpecialShipmentDetails from "./SpecialShipmentDetails";
import SpecialShipmentProduct from "./SpecialShipmentProduct";

const StyledWrapper = styled(StyledSection)`
  position: relative;
  border: 1px solid #f0f0f0;
  margin: 16px 25px;
  padding: 8px 11px;
`;
const ButtonWrapper = styled.div`
  position: absolute;
  top: 9px;
  right: 20px;
  z-index: 2;
`;

export default function SpecialShipmentDetailsPage() {
  const dispatch = useDispatch();
  const { shipmentId: shipmentIdStr } = useParams();
  const shipmentId = Number(shipmentIdStr);
  const { specialShipmentDetail: details, specialShipmentProductList } = useSelector(specialShipmentState);
  const editDisabled = !details?.canEdit; // 用BE回傳canEdit判斷是否可以編輯，由三種狀態判斷：1. 申請狀態：未送出 2. 審核狀態：待審核or審核不通過 3. 訂單狀態：未作廢
  const canSubmit = details?.canSubmit;

  const [activeTab, setActiveTab] = useState("details");

  const handelStatusChange = (processType: ProcessType) => {
    dispatch(handleSpecialShipmentStatus({ processType, shipmentId }));
  };

  useEffect(() => {
    if (shipmentId) {
      dispatch(fetchSpecialShipmentDetail(shipmentId));
    }
  }, [dispatch, shipmentId]);

  return (
    <StyledWrapper>
      <PageTitle title="特例出貨" />
      <ButtonWrapper>
        {activeTab === "details" ? (
          <Space>
            <Button
              type="default"
              disabled={!!canSubmit || !editDisabled}
              onClick={() => handelStatusChange(ProcessType.REJECT)}
            >
              審核不通過
            </Button>
            <Button
              type="default"
              disabled={!!canSubmit || !editDisabled}
              onClick={() => handelStatusChange(ProcessType.APPROVE)}
            >
              審核通過
            </Button>
            <Button type="primary" onClick={() => dispatch(setOpenShipmentFormType("update"))} disabled={editDisabled}>
              編輯
            </Button>
          </Space>
        ) : (
          <Button
            type="default"
            onClick={() => handelStatusChange(ProcessType.APPLY)}
            disabled={!details?.canSubmit || specialShipmentProductList?.results.length === 0}
          >
            送出申請
          </Button>
        )}
      </ButtonWrapper>
      {details ? (
        <Tabs
          onChange={(activeKey: string) => setActiveTab(activeKey)}
          type="card"
          items={[
            { label: "編輯出貨單", key: "details", children: <SpecialShipmentDetails itemDetails={details} /> },
            { label: "出貨商品", key: "products", children: <SpecialShipmentProduct /> },
          ]}
        />
      ) : (
        <Skeleton />
      )}
    </StyledWrapper>
  );
}
