import React, { useEffect, useState } from "react";
import { ReturnProduct, ProductStockInfo } from "@page/ReturnOrder/interfaces";
import PopupBackground from "@component/PopupBackground";
import { Button, DatePicker, Form, Input, message, Row, Select, Spin } from "antd";
import moment, { Moment } from "moment";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { fetchCreateProduct, fetchUpdateProduct } from "@redux/returnOrderSlice";
import storageTypeOptions from "@constant/StorageType";
import returnOrderApi from "@api/returnOrderApi";

const Wrapper = styled(Form)`
  position: relative;
  min-width: 650px;
  padding: 30px;
  background: ${({ theme }) => theme.colorNeutral100};
  overflow: auto;
  .ant-form-item-label {
    max-width: 131px;
    min-width: 131px;
    text-align: left;
  }
  .ant-form-item-required::before {
    order: 1;
  }
  .ant-form-item-required::after {
    display: none;
  }
  .ant-input {
    width: 230px;
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-select,
  .ant-picker {
    width: 230px;
  }
`;

const Notice = styled.span`
  margin-top: 5px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colorNeutral500};
  ${fontStyle("14px", "22px")};
`;
const ErrorText = styled.div`
  margin-left: 135px;
  color: ${({ theme }) => theme.colorSuccess500};
`;

type Props = {
  currentProduct?: ReturnProduct;
  onClose: () => void;
  sourceWarehouseCode: string;
  returnOrderId: string;
};

export default function EditProductPopup(props: Props) {
  const { currentProduct, onClose, sourceWarehouseCode, returnOrderId } = props;
  const [stocksInfo, setStocksInfo] = useState<any>();
  const isTCAT = sourceWarehouseCode === "TCAT";

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [stockStorageTypes, setStockStorageTypes] = useState<ProductStockInfo[]>([]);
  const [allStockList, setAllStockList] = useState<ProductStockInfo[]>([]);
  const [stocksForEffectiveDate, setStocksForEffectiveDate] = useState<ProductStockInfo[]>([]);
  const [stocksForBatch, setStocksForBatch] = useState<ProductStockInfo[]>([]);
  const [sameStorageTypeStocks, setSameStorageTypeStocks] = useState<ProductStockInfo[]>([]);

  const [isProductInfoFetching, setIsProductInfoFetching] = useState(false);

  const skuFormValue: string = Form.useWatch("sku", form);
  const stockFormValue: string = Form.useWatch("stock", form);
  const sourceStorageTypeFormValue: string = Form.useWatch("sourceStorageType", form);
  const effectiveDateFormValue: string = Form.useWatch("effectiveDate", form);

  const stocksByWarehouseDetail = storageTypeOptions.filter((option) =>
    stockStorageTypes.some((stock) => option.key === stock.storageType),
  );

  const handleSubmit = (formValue: any) => {
    if (currentProduct) {
      // 編輯
      const params = {
        returnOrderId,
        productId: currentProduct.id,
        sourceStorageType: sourceWarehouseCode === "TCAT" ? "GOOD" : formValue.sourceStorageType,
        sku: formValue.sku,
        qty: Number(formValue.qty),
        effectiveDate: formValue.effectiveDate === "-1" ? null : moment(formValue.effectiveDate).format("YYYY-MM-DD"),
        batch: formValue.batch,
      };
      dispatch(fetchUpdateProduct(params));
    } else {
      // 新增
      const params = {
        returnOrderId,
        sourceStorageType: sourceWarehouseCode === "TCAT" ? "GOOD" : formValue.sourceStorageType,
        sku: formValue.sku,
        qty: Number(formValue.qty),
        effectiveDate: formValue.effectiveDate === "-1" ? null : moment(formValue.effectiveDate).format("YYYY-MM-DD"),
        batch: formValue.batch,
      };
      dispatch(fetchCreateProduct(params));
    }
    onClose();
  };

  const handleBatchChange = () => {
    form.setFieldsValue({
      stock: stocksForBatch[0].canSalesQty,
    });
  };

  useEffect(() => {
    setErrorMessage("");
    if (!skuFormValue) {
      form.setFieldsValue({
        barcode: undefined,
        productName: undefined,
        weightedCost: undefined,
        stock: undefined,
      });
    }
    const fetchProductDetailBySku = async () => {
      setIsProductInfoFetching(true);
      const payload = { sku: skuFormValue, warehouse: sourceWarehouseCode };
      try {
        const response = await returnOrderApi.fetchProductBarcodeBySku(payload);
        form.setFieldsValue({
          barcode: response.results[0].barcode,
          productName: response.results[0].productName,
          weightedCost: response.results[0].weightedCost,
        });
        if (response.results.length === 0) setErrorMessage("查無對應商品，請確認商品品號是否正確");
      } catch (error: any) {
        message.error(error.message);
      } finally {
        setIsProductInfoFetching(false);
      }
    };
    const fetchProductStockBySku = async () => {
      setIsProductInfoFetching(true);
      const payload = { sku: skuFormValue, warehouse: sourceWarehouseCode };
      try {
        const response = await returnOrderApi.fetchProductStockBySku(payload);
        setStocksInfo(response);
      } catch (error: any) {
        message.error(error.message);
      } finally {
        setIsProductInfoFetching(false);
      }
    };

    const timer = setTimeout(() => {
      if (skuFormValue && sourceWarehouseCode) {
        fetchProductDetailBySku();
        fetchProductStockBySku();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [form, skuFormValue, sourceWarehouseCode]);

  useEffect(() => {
    if (!stocksInfo) return;
    const stockInfo = stocksInfo[0]?.stockInfo;
    setAllStockList(stockInfo);
    const stockMap = new Map(); // 濾掉重複的 storageType
    const stocks = stockInfo.filter((item: any) => {
      if (!stockMap.has(item.storageType)) {
        stockMap.set(item.storageType, true);
        return true;
      }
      return false;
    });
    setStockStorageTypes(stocks);
  }, [stocksInfo]);

  useEffect(() => {
    if (sourceStorageTypeFormValue) {
      const filteredStock = allStockList.filter((stockInfo) => {
        return stockInfo.storageType === sourceStorageTypeFormValue;
      });
      setSameStorageTypeStocks(filteredStock);
    }
  }, [sourceStorageTypeFormValue, allStockList]);

  useEffect(() => {
    const stockMap = new Map();
    const stocks = sameStorageTypeStocks.filter((item) => {
      if (!stockMap.has(item.effectiveDate)) {
        stockMap.set(item.effectiveDate, true);
        return true;
      }
      return false;
    });
    setStocksForEffectiveDate(stocks);
  }, [sameStorageTypeStocks]);

  useEffect(() => {
    if (effectiveDateFormValue) {
      const stockMap = new Map();
      const stocks = sameStorageTypeStocks.filter((item) => {
        if (!stockMap.has(item.batch)) {
          stockMap.set(item.batch, true);
          return true;
        }
        return false;
      });
      setStocksForBatch(stocks);
    }
  }, [effectiveDateFormValue, sameStorageTypeStocks]);

  const disablePastDates = (current: Moment | null): boolean => {
    return current ? current < moment().startOf("day") : false;
  };

  useEffect(() => {
    if (currentProduct) {
      setIsProductInfoFetching(true);
      form.setFieldsValue({
        ...currentProduct,
        effectiveDate:
          sourceWarehouseCode === "RENTRAP"
            ? currentProduct.effectiveDate || "-1"
            : currentProduct.effectiveDate && moment(currentProduct.effectiveDate),
      });
    } else {
      form.resetFields();
    }
  }, [currentProduct, sourceWarehouseCode, form]);

  useEffect(() => {
    // for 黑貓庫存量
    if (!stocksInfo || !isTCAT) return;
    const stockInfo = stocksInfo[0]?.stockInfo;
    form.setFieldsValue({
      stock: stockInfo[0].canSalesQty,
    });
  }, [stocksInfo, isTCAT, form]);

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        labelAlign="left"
        colon={false}
        onFinish={handleSubmit}
      >
        {/* // 藍田 type */}
        {sourceWarehouseCode === "RENTRAP" && (
          <Spin spinning={isProductInfoFetching}>
            <Form.Item
              shouldUpdate
              label="SKU(品號)"
              name="sku"
              labelAlign="left"
              rules={[{ required: true, message: "" }]}
            >
              <Input
                onChange={() =>
                  form.setFieldsValue({
                    sourceStorageType: undefined,
                  })
                }
              />
            </Form.Item>
            <ErrorText style={{ color: "#EC6922" }}>{errorMessage}</ErrorText>
            <Row>
              <Form.Item label="barcode" name="barcode" labelAlign="left">
                <Input disabled />
              </Form.Item>
              <Notice>*輸入品號後自動帶入</Notice>
            </Row>
            <Row>
              <Form.Item label="商品名稱" name="productName" labelAlign="left">
                <Input disabled />
              </Form.Item>
              <Notice>*輸入品號後自動帶入</Notice>
            </Row>
            <Row>
              <Form.Item label="成本" name="weightedCost" labelAlign="left">
                <Input disabled />
              </Form.Item>
              <Notice>*輸入品號後自動帶入</Notice>
            </Row>
            <Form.Item
              shouldUpdate
              label="倉別"
              name="sourceStorageType"
              labelAlign="left"
              rules={[{ required: true, message: "" }]}
            >
              <Select
                style={{ width: "230px" }}
                onChange={() =>
                  form.setFieldsValue({
                    effectiveDate: undefined,
                    batch: undefined,
                  })
                }
              >
                {stocksByWarehouseDetail.map((storage) => (
                  <Select.Option value={storage.value}>
                    {storage.value}
                    {storage.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="商品效期"
              name="effectiveDate"
              labelAlign="left"
              rules={[{ required: true, message: "" }]}
            >
              <Select placeholder="請先選擇倉別" disabled={!sourceStorageTypeFormValue}>
                {stocksForEffectiveDate.map((stockInfo) => {
                  return (
                    <Select.Option
                      key={`${stockInfo.effectiveDate}-${stockInfo.batch}`}
                      value={stockInfo.effectiveDate || "-1"}
                    >
                      {stockInfo.effectiveDate ? stockInfo.effectiveDate : "無效期限制"}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="批號" name="batch" labelAlign="left">
              <Select onChange={handleBatchChange} placeholder="請先選擇效期" disabled={!effectiveDateFormValue}>
                {stocksForBatch.map((stockInfo) => {
                  return (
                    <Select.Option key={`${stockInfo.effectiveDate}-${stockInfo.batch}`} value={stockInfo.batch}>
                      {stockInfo.batch ? stockInfo.batch : "無批號"}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Row>
              <Form.Item label="庫存量" name="stock" labelAlign="left">
                <Input disabled />
              </Form.Item>
            </Row>
            <Form.Item
              label="商品數量(pcs)"
              name="qty"
              labelAlign="left"
              rules={[
                { required: true, message: "" },
                {
                  validator: (rule, value) => (value > stockFormValue ? Promise.reject() : Promise.resolve()),
                  message: "商品數量超過庫存量，請修正",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Row justify="end" align="middle">
              <Button type="default" style={{ marginRight: 10 }} onClick={onClose}>
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                確定
              </Button>
            </Row>
          </Spin>
        )}
        {/* // 黑貓 type */}
        {sourceWarehouseCode === "TCAT" && (
          <Spin spinning={isProductInfoFetching}>
            <Form.Item
              shouldUpdate
              label="SKU(品號)"
              name="sku"
              labelAlign="left"
              rules={[{ required: true, message: "" }]}
            >
              <Input />
            </Form.Item>
            <ErrorText style={{ color: "#EC6922" }}>{errorMessage}</ErrorText>
            <Row>
              <Form.Item label="商品名稱" name="productName" labelAlign="left">
                <Input disabled />
              </Form.Item>
              <Notice>*輸入品號後自動帶入</Notice>
            </Row>
            <Row>
              <Form.Item label="成本" name="weightedCost" labelAlign="left">
                <Input disabled />
              </Form.Item>
              <Notice>*輸入品號後自動帶入</Notice>
            </Row>

            <Row>
              <Form.Item label="庫存量" name="stock" labelAlign="left">
                <Input disabled />
              </Form.Item>
              <Notice>*輸入品號後自動帶入</Notice>
            </Row>
            <Form.Item label="商品效期" name="effectiveDate" labelAlign="left">
              <DatePicker disabledDate={disablePastDates} />
            </Form.Item>
            <Form.Item
              label="商品數量(pcs)"
              name="qty"
              labelAlign="left"
              rules={[
                { required: true, message: "" },
                {
                  validator: (rule, value) => (value > stockFormValue ? Promise.reject() : Promise.resolve()),
                  message: "商品數量超過庫存量，請修正",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Row justify="end" align="middle">
              <Button type="default" style={{ marginRight: 10 }} onClick={onClose}>
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                確定
              </Button>
            </Row>
          </Spin>
        )}
      </Wrapper>
    </PopupBackground>
  );
}
