import React, { FC, useEffect, useMemo } from "react";
import { Select, Table, Pagination } from "antd";
import { SelectValue } from "antd/lib/select";
import styled from "styled-components";
import { fetchProductStockList, updateProductStockListParam } from "@redux/productEditSlice";
import { ProductStockListItem } from "@api/productApi";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import { useParams } from "react-router-dom";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-bottom: 21px;
`;
const PageSizeSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  padding-left: 16px;
  margin-bottom: 16px;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
`;
const TableNoWrap = styled.div`
  display: inline-block;
  white-space: nowrap;
`;

const pageSizeOptions = [20, 50, 100];

const tableColumns = [
  {
    title: <TableNoWrap>有效日期</TableNoWrap>,
    key: "effectiveDate",
    dataIndex: "effectiveDate",
    width: 110,
  },
  {
    title: <TableNoWrap>良/壞品</TableNoWrap>,
    key: "qualified",
    dataIndex: "qualified",
    render: (value: ProductStockListItem["qualified"]) => (value ? "良品" : "壞品"),
    width: 110,
  },
  {
    title: <TableNoWrap>庫存數</TableNoWrap>,
    key: "stockQty",
    dataIndex: "stockQty",
    width: 110,
  },
];

const ProductStock: FC = () => {
  const dispatch = useAppDispatch();
  const { id: productId } = useParams();

  const productStockListResult = useSelector((state: RootState) => state.productEdit.productStockListResult);
  const productStockListParam = useSelector((state: RootState) => state.productEdit.productStockListParam);
  const isFetchingProductStockList = useSelector((state: RootState) => state.productEdit.isFetchingProductStockList);

  const { count, results: productStockList } = productStockListResult;
  const { offset, limit } = productStockListParam;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    const filter = {
      limit: 20,
      offset: 0,
    };

    dispatch(updateProductStockListParam(filter));
    dispatch(fetchProductStockList(Number(productId)));
  }, [dispatch, productId]);

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...productStockListParam,
      limit: value as number,
    };

    dispatch(updateProductStockListParam(filter));
    dispatch(fetchProductStockList(Number(productId)));
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...productStockListParam,
      offset: (page - 1) * limit,
    };
    dispatch(updateProductStockListParam(filter));
    dispatch(fetchProductStockList(Number(productId)));
  };

  return (
    <Wrapper>
      <Header>
        總共
        {count}
        筆, 每頁顯示
        <PageSizeSelect value={limit} onChange={(value) => handleOnPageSizeChange(value as SelectValue)}>
          {pageSizeOptions.map((option) => (
            <Select.Option value={option}>{option}</Select.Option>
          ))}
        </PageSizeSelect>
        筆
      </Header>
      <TableContainer>
        <Table
          dataSource={productStockList}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          pagination={false}
          loading={isFetchingProductStockList}
        />
      </TableContainer>
      <Footer>
        <Pagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </Footer>
    </Wrapper>
  );
};

export default ProductStock;
