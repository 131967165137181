import configSettingApi, { EnvironmentResult } from "@api/configSettingApi";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";

interface IState {
  isFetching: boolean;
  environmentResult: EnvironmentResult;
}
export const initialState: IState = {
  isFetching: false,
  environmentResult: {
    defaultRecommendPageIds: [],
    vipPromotionStartDate: "",
    vipPromotionContent: "",
    defaultAdditionPurchaseApplyMaxQty: "",
    promotionMaxNumberOfBindRules: "",
    vipPromotionImage: "",
    cvsLimitLongestEdge: "",
    cvsLimitSecondLongestEdge: "",
    cvsLimitShortestEdge: "",
    cvsLimitVolume: "",
    cvsLimitWeight: "",
    cvsLimitPrice: "",
    newCustomerPromotionRuleIds: [],
    vipCustomerRenewPromotionRuleIds: [],
    searchKeywords: [],
    vipPromotionEndDate: "",
    deployCvsSuspendStartDate: "",
    deployCvsSuspendEndDate: "",
  },
};

export const fetchEnvironmentList = createAsyncThunk("systemSetting/fetchEnvironmentList", async () => {
  const response = await configSettingApi.fetchEnvironmentList();
  return response;
});

export const fetchUpdateEnvironmentList = createAsyncThunk(
  "systemSetting/fetchUpdateEnvironmentList",
  async (params: EnvironmentResult, thunkApi) => {
    const response = await configSettingApi.fetchUpdateEnvironmentList(params);
    message.success("已儲存");
    thunkApi.dispatch(fetchEnvironmentList());
    return response;
  },
);

const configSettingSlice = createSlice({
  name: "systemSetting",
  initialState,
  reducers: {
    reset: () => initialState,
    updateEnvironmentList: (state, action: PayloadAction<EnvironmentResult>) => {
      state.environmentResult = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEnvironmentList.fulfilled, (state, action) => {
      state.environmentResult = action.payload;
    });
  },
});

export const { updateEnvironmentList } = configSettingSlice.actions;

export default configSettingSlice.reducer;
