import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setOpenShipmentFormType, specialShipmentState } from "@redux/specialShipmentSlice";
import SpecialShipmentList from "./SpecialShipmentList";
import SpecialShipmentDetailsPage from "./SpecialShipmentDetailsPage";
import SpecialShipmentFormModal from "./SpecialShipmentFormModal";

const SpecialShipmentPage: FC = () => {
  const dispatch = useDispatch();
  const { openShipmentFormType } = useSelector(specialShipmentState);

  return (
    <>
      <Routes>
        <Route path="/" element={<SpecialShipmentList />} />
        <Route path="/edit/:shipmentId" element={<SpecialShipmentDetailsPage />} />
      </Routes>
      {openShipmentFormType && (
        <SpecialShipmentFormModal
          type={openShipmentFormType}
          onClose={() => dispatch(setOpenShipmentFormType(undefined))}
        />
      )}
    </>
  );
};

export default SpecialShipmentPage;
