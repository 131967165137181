import { BatchUpdateOrderLines, convertCurrencyType, OrderLinesList } from "@api/throughShipment/throughShipmentApi";
import PopupBackground from "@component/PopupBackground";
import { externalOrderState, fetchBatchUpdateOrderLines } from "@redux/externalOrderSlice";
import { Button, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import ExpandIcon from "@assets/expand.png";
import storageTypeOptions from "@constant/StorageType";

const Wrapper = styled.div`
  padding: 20px;
  width: 1024px;
  background: ${({ theme }) => theme.colorNeutral100};
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
`;
const CustomButton = styled(Button)<{ marginRight?: string; width?: string }>`
  margin-right: ${({ marginRight }) => marginRight || ""};
  width: ${({ width }) => width || ""};
`;
const CustomRow = styled(Row)`
  margin-top: 13px;
`;

const Notes = styled.div<{ isExpanded?: boolean }>`
  text-align: left;
  margin: 15px 0;
  white-space: normal;
  overflow-y: ${({ isExpanded }) => (isExpanded ? "hidden" : "scroll")};
  max-height: 112px;
`;
const ExpandIconImg = styled.img`
  background-color: #fff;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
`;
const NotesWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
`;
const NameWrapper = styled.div``;

type Props = {
  orderLinesList: OrderLinesList[];
  onClose: () => void;
};

const showExpandIconLength = 60;

export default function CheckOrderList(props: Props) {
  const { orderLinesList, onClose } = props;

  const dispatch = useDispatch();
  const { externalOrderInfo } = useSelector(externalOrderState);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const onSubmit = () => {
    const result: BatchUpdateOrderLines[] = orderLinesList.map((item) => {
      return {
        id: item.id,
        batchNumber: item.batchNumber || "",
        effectiveDate: item.effectiveDate || null,
        canAcceptDays: item.canAcceptDays || undefined,
        cost: Number(item.cost),
        quotedPriceCurrency: Number(item.quotedPriceCurrency),
        quotedPriceTwd: Number(item.quotedPriceTwd),
        pcsPerCarton: item.pcsPerCarton,
        orderedQty: item.orderedQty,
        notes: item.notes,
        storageType: item.storageType,
      };
    });
    dispatch(fetchBatchUpdateOrderLines(result));
    onClose();
  };

  const clickExpandIcon = (rowId: number) => {
    if (expandedRows.includes(rowId)) {
      setExpandedRows(expandedRows.filter((id) => id !== rowId));
    } else {
      setExpandedRows(expandedRows.concat(rowId));
    }
  };

  const columns: ColumnsType<OrderLinesList> = [
    {
      key: "id",
      dataIndex: "id",
      title: "編號",
    },
    {
      key: "no",
      dataIndex: "no",
      title: "商品品號",
    },
    {
      key: "sku",
      dataIndex: "sku",
      title: "SKU",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "品名",
      width: 250,
      render: (data, value) => {
        return <NameWrapper id={value.id.toString()}>{data}</NameWrapper>;
      },
    },
    {
      key: "storageType",
      dataIndex: "storageType",
      title: "倉別",
      render: (value) => storageTypeOptions.find((type) => type.key === value)?.name || "-",
    },
    {
      key: "effectiveDateBatch",
      dataIndex: "effectiveDateBatch",
      title: "效期＿批號",
    },
    {
      key: "canAcceptDays",
      dataIndex: "canAcceptDays",
      title: "允收天數",
    },
    {
      key: "cost",
      dataIndex: "cost",
      title: "商品成本(TWD)",
    },
    {
      key: "quotedPriceCurrency",
      dataIndex: "quotedPriceCurrency",
      title: `商品報價(${convertCurrencyType(externalOrderInfo!.currency)})`,
    },
    {
      key: "quotedPriceTwd",
      dataIndex: "quotedPriceTwd",
      title: "商品報價(TWD)",
    },
    {
      key: "orderedQty",
      dataIndex: "orderedQty",
      title: "總數量(pcs)",
    },
    {
      key: "notes",
      dataIndex: "notes",
      title: "備註",
      width: 250,
      render: (data, value) => {
        const isExpanded = data?.length && !expandedRows.includes(value.id);

        const getCharLength = () => {
          // 若匹配中文字符則佔寬+2,非中文+1
          const regex = /[\u4e00-\u9fa5]/;
          let count = 0;
          for (let i = 0; i < data?.length; i += 1) {
            const char = data[i];
            if (regex.test(char)) {
              count += 2;
            } else {
              count += 1;
            }
          }
          return count;
        };

        const showExpandIcon = getCharLength() >= showExpandIconLength;

        const cutString = (str: string, showLength: number) => {
          // 以中文字的寬度來判斷，若小於 showLength 可以直接 return
          if (str.length * 2 <= showLength) {
            return str;
          }
          let strLength = 0;
          let showText = "";
          for (let i = 0; i < str.length; i += 1) {
            const char = str.charAt(i);
            const charCode = str.charCodeAt(i);
            // Unicode 編碼值大於 128 代表中文字符 -> 字符的寬度是 2 個字符位置。
            const charWidth = charCode > 128 ? 2 : 1;
            if (strLength + charWidth <= showLength) {
              showText += char;
              strLength += charWidth;
            }
          }
          if (strLength < str.length * 2) {
            showText += "...";
          }
          return showText;
        };

        return (
          <div>
            <NotesWrapper>
              {!isExpanded ? (
                <Notes isExpanded={isExpanded} id={`${value.id}-note`}>
                  {data}
                </Notes>
              ) : (
                cutString(data, 50)
              )}
            </NotesWrapper>
            {showExpandIcon && <ExpandIconImg src={ExpandIcon} onClick={() => clickExpandIcon(value.id)} />}
          </div>
        );
      },
    },
  ];

  return (
    <PopupBackground close={onClose}>
      <Wrapper>
        <Title>確認出貨商品</Title>
        <Table columns={columns} dataSource={orderLinesList} pagination={false} scroll={{ x: 2000, y: 325 }} />
        <CustomRow justify="end" align="middle">
          <CustomButton marginRight="12px" onClick={onClose}>
            取消
          </CustomButton>
          <CustomButton type="primary" disabled={orderLinesList.length === 0} onClick={onSubmit}>
            確認
          </CustomButton>
        </CustomRow>
      </Wrapper>
    </PopupBackground>
  );
}
