import { ApprovalStatus } from "@api/utils/normalizeCustomsClearance";
import {
  clearError,
  customsClearanceState,
  fetchCustomsClearance,
  fetchCustomsClearancesApprove,
  fetchCustomsClearancesRecalculate,
  fetchPurchaseLines,
  fetchPurchaseList,
} from "@redux/customsClearanceSlice";
import { Button, message, Modal, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CustomsClearanceForm from "./CustomsClearanceForm";
import CustomsClearanceInfo from "./CustomsClearanceInfo";
import CustomsClearanceProduct from "./CustomsClearanceProduct";

const Wrapper = styled.div`
  padding: 17px 20px 17px 25px;
`;

const InfoWrapper = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 8px 20px 8px 12px;
  position: relative;
`;

const ButtonRow = styled(Row)`
  position: absolute;
  right: 20px;
  top: 9px;
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

export default function CustomsClearanceEdit() {
  const { customsClearanceId } = useParams();
  const integerCustomsClearanceId = parseInt(customsClearanceId, 10);

  const dispatch = useDispatch();
  const {
    customsClearanceInfo,
    purchaseLinesFilter,
    authorityErrorMessage,
    isAuthorityDone,
    purchaseLinesResult,
  } = useSelector(customsClearanceState);

  const [activeKey, setActiveKey] = useState<string>("edit");
  const [openForm, setOpenForm] = useState<boolean>(false);

  const financialReviewPassed = () => {
    dispatch(fetchCustomsClearancesApprove(integerCustomsClearanceId));
  };

  const recalculateCost = () => {
    if (purchaseLinesResult.results.abnormalCost) {
      Modal.confirm({
        title: "有商品進價疑似異常，是否仍要重新計算成本?",
        icon: <ExclamationCircleOutlined />,
        okText: "是",
        cancelText: "否",
        onOk() {
          dispatch(fetchCustomsClearancesRecalculate(integerCustomsClearanceId));
        },
      });
    } else {
      dispatch(fetchCustomsClearancesRecalculate(integerCustomsClearanceId));
    }
  };

  useEffect(() => {
    if (authorityErrorMessage) {
      Modal.warning({
        title: authorityErrorMessage,
        okText: "我知道了",
        onOk: (close) => {
          dispatch(clearError());
          close();
        },
      });
    }
  }, [authorityErrorMessage, dispatch]);

  useEffect(() => {
    if (isAuthorityDone) {
      message.success("成功");
    }
  }, [isAuthorityDone, dispatch]);

  useEffect(() => {
    dispatch(fetchCustomsClearance(integerCustomsClearanceId));
  }, [dispatch, integerCustomsClearanceId]);

  useEffect(() => {
    dispatch(
      fetchPurchaseList({ ...purchaseLinesFilter, customsClearance: integerCustomsClearanceId, limit: 100, offset: 0 }),
    );
  }, [dispatch, integerCustomsClearanceId, purchaseLinesFilter]);

  useEffect(() => {
    dispatch(
      fetchPurchaseLines({
        ...purchaseLinesFilter,
        customsClearance: integerCustomsClearanceId,
        page: 1,
      }),
    );
  }, [dispatch, integerCustomsClearanceId, purchaseLinesFilter]);

  return (
    <Wrapper>
      <PageTitle title={`報關 - ${customsClearanceInfo?.tariffNumber}`} />
      <InfoWrapper>
        <Tabs type="card" onChange={(key) => setActiveKey(key)}>
          <Tabs.TabPane tab="編輯報關單" key="edit">
            {customsClearanceInfo && <CustomsClearanceInfo customsClearance={customsClearanceInfo} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab="報關商品" key="product">
            <CustomsClearanceProduct />
          </Tabs.TabPane>
        </Tabs>
        <ButtonRow align="middle">
          <CustomButton
            disabled={customsClearanceInfo?.approvalStatus === ApprovalStatus.FINISH}
            onClick={financialReviewPassed}
          >
            財務審核通過
          </CustomButton>
          <CustomButton disabled={customsClearanceInfo?.isCostRecalculated} onClick={recalculateCost}>
            重新計算成本
          </CustomButton>
          {activeKey === "edit" && (
            <Button type="primary" onClick={() => setOpenForm(true)}>
              編輯
            </Button>
          )}
        </ButtonRow>
        {openForm && (
          <CustomsClearanceForm
            customsClearancesId={parseInt(customsClearanceId, 10)}
            close={() => setOpenForm(false)}
          />
        )}
      </InfoWrapper>
    </Wrapper>
  );
}
