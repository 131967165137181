import { PlusCircleFilled } from "@ant-design/icons";
import { ProcessingLineItemInfo, ProcessingLineType, StockDetail } from "@api/tryall/ProcessingApplicationApi";
import DashBoard from "@component/Common/DashBoard";
import PopupAddAndUpdateModal from "@component/Common/PopupAddAndUpdateModal";
import IntegratedTable from "@component/Table/IntegratedTable";
import storageTypeOptions from "@constant/StorageType";
import useDebounce from "@hooks/useDebounce";
import {
  addProcessingLineItem,
  deleteProcessingLineItem,
  fetchProcessingLineList,
  fetchProductStockDetail,
  ProcessingApplicationState,
  resetProductStockDetail,
  updateProcessingLineItem,
} from "@redux/ProcessingApplicationSlice";
import { Button, Col, Form, Input, InputNumber, message, Row, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import { FlexBox, TabContentWrapper } from "src/styles/common";
import styled from "styled-components";
import { ProcessingStatus } from "./ProcessingStatus";

const BlockDiv = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const ProcessingApplicationContent = () => {
  const { details } = useSelector(ProcessingApplicationState);
  const dispatch = useDispatch();
  const { id: applicationIdStr } = useParams();
  const applicationId = Number(applicationIdStr);
  const [showTargetModal, setShowTargetModal] = useState(false);
  const [showSourceModal, setShowSourceModal] = useState(false);
  const [editDate, setEditDate] = useState<ProcessingLineItemInfo | undefined>(undefined);
  const { isLineListFetching, processingLineList } = useSelector(ProcessingApplicationState);
  const {
    [ProcessingLineType.TARGET]: processingLineTargetList,
    [ProcessingLineType.SOURCE]: processingLineSourceList,
  } = processingLineList;

  const accTotalMethod = (data: ProcessingLineItemInfo[]) => {
    return data.reduce(
      (acc, cur) => {
        const { cost, qty } = cur;
        acc.cost += cost * qty;
        acc.qty += qty;
        return acc;
      },
      {
        cost: 0,
        qty: 0,
      },
    );
  };

  const dashBoardSourceData = useMemo(() => {
    const totalObj = accTotalMethod(processingLineSourceList);
    return [
      { name: "總成本", value: totalObj.cost },
      { name: "總加工量", value: totalObj.qty },
    ];
  }, [processingLineSourceList]);

  const dashBoardTargetData = useMemo(() => {
    const totalObj = accTotalMethod(processingLineTargetList);
    return [{ name: "總成本", value: totalObj.cost }];
  }, [processingLineTargetList]);

  const showModal = (type: ProcessingLineType) => {
    if (type === ProcessingLineType.TARGET) {
      setShowTargetModal(true);
      return;
    }
    setShowSourceModal(true);
  };

  const closeModal = (type: ProcessingLineType) => {
    if (type === ProcessingLineType.TARGET) {
      setShowTargetModal(false);
    } else {
      setShowSourceModal(false);
    }
    setEditDate(undefined);
  };

  const handleDelete = (processingAppId: number, type: ProcessingLineType, itemId: number) => {
    dispatch(deleteProcessingLineItem({ processingAppId, itemId }));
  };

  const tableColumns = (type: ProcessingLineType): ColumnsType<any> => {
    return [
      {
        key: "no",
        dataIndex: "no",
        title: "商品品號",
        width: "10%",
      },
      {
        key: "name",
        dataIndex: "name",
        title: "商品名稱",
        width: "20%",
      },
      {
        key: "storageType",
        dataIndex: "storageType",
        title: "倉別",
        width: 100,
        align: "center",
        render: (value) => {
          return storageTypeOptions.find((option) => option.value === value)?.name || value;
        },
      },
      {
        key: "",
        dataIndex: "",
        title: "效期_批號",
        width: "20%",
        align: "center",
        render: (value, record) => {
          return `${record.effectiveDate || ""}_${record.batch || ""}`;
        },
      },
      {
        key: "cost",
        dataIndex: "cost",
        title: "成本",
        width: 120,
        align: "center",
      },
      {
        key: "qty",
        dataIndex: "qty",
        title: "應出數量",
        width: 100,
        align: "center",
      },
      {
        key: "",
        dataIndex: "",
        title: "",
        render: (_: any, record) => (
          <FlexBox>
            <Button
              type="link"
              onClick={() => {
                showModal(type);
                setEditDate(record);
              }}
              disabled={details?.status !== ProcessingStatus.PENDING}
            >
              編輯
            </Button>
            <Button
              type="link"
              onClick={() => {
                if (record.id) handleDelete(applicationId, type, record.id);
              }}
              disabled={details?.status !== ProcessingStatus.PENDING}
            >
              刪除
            </Button>
          </FlexBox>
        ),
      },
    ];
  };

  useEffect(() => {
    dispatch(fetchProcessingLineList(applicationId));
  }, [applicationId, dispatch]);

  return (
    <TabContentWrapper>
      {showTargetModal && (
        <TargetLineItemAddModal
          onClose={() => {
            closeModal(ProcessingLineType.TARGET);
          }}
          editData={editDate}
          type={ProcessingLineType.TARGET}
        />
      )}
      {showSourceModal && (
        <SourceLineItemAddModal
          onClose={() => {
            closeModal(ProcessingLineType.SOURCE);
          }}
          editData={editDate}
          type={ProcessingLineType.TARGET}
        />
      )}
      <BlockDiv>
        <Row align="middle" gutter={[24, 0]}>
          <Col>
            <Row gutter={[0, 8]}>
              <Col>來源品項</Col>
            </Row>
            <Row gutter={[0, 8]}>
              <Col>
                <Button
                  type="primary"
                  icon={<PlusCircleFilled />}
                  onClick={() => showModal(ProcessingLineType.SOURCE)}
                  disabled={details?.status !== ProcessingStatus.PENDING}
                >
                  新增
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <DashBoard dataset={dashBoardSourceData} isFetching={isLineListFetching} />
          </Col>
        </Row>
        <IntegratedTable
          isPagination={false}
          dataSource={processingLineSourceList}
          columns={tableColumns(ProcessingLineType.SOURCE)}
          scroll={{ y: 300 }}
          tableLayout="auto"
          loading={isLineListFetching}
          rowKey="name"
        />
      </BlockDiv>
      <BlockDiv>
        <Row align="middle" gutter={[24, 0]}>
          <Col>
            <Row gutter={[0, 8]}>
              <Col>加工品項</Col>
            </Row>
            <Row gutter={[0, 8]}>
              <Col>
                <Button
                  type="primary"
                  icon={<PlusCircleFilled />}
                  onClick={() => {
                    if (processingLineSourceList.length === 0) {
                      message.error("請先新增來源品項");
                      return;
                    }
                    showModal(ProcessingLineType.TARGET);
                  }}
                  disabled={details?.status !== ProcessingStatus.PENDING}
                >
                  新增
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <DashBoard dataset={dashBoardTargetData} isFetching={isLineListFetching} />
          </Col>
        </Row>
        <IntegratedTable
          isPagination={false}
          dataSource={processingLineTargetList}
          columns={tableColumns(ProcessingLineType.TARGET)}
          scroll={{ y: 500 }}
          tableLayout="auto"
          loading={isLineListFetching}
          rowKey="name"
        />
      </BlockDiv>
    </TabContentWrapper>
  );
};

export default ProcessingApplicationContent;

type Props = {
  onClose: () => void;
  editData?: ProcessingLineItemInfo;
  type: ProcessingLineType;
};

// 加工品項 Modal
const TargetLineItemAddModal: FC<Props> = (props) => {
  const { onClose, editData } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { id: applicationIdStr } = useParams();
  const applicationId = Number(applicationIdStr);
  const { isFetching, productStockDetail, isNoStockChecked } = useSelector(ProcessingApplicationState);

  const noCheckState = useMemo(() => {
    if (isFetching) return "validating";
    return isNoStockChecked ? "error" : undefined;
  }, [isFetching, isNoStockChecked]);

  const fetchNoInfo = useDebounce((value: string) => {
    if (value) {
      dispatch(fetchProductStockDetail(value));
    } else {
      dispatch(resetProductStockDetail());
      form.resetFields();
    }
  }, 1000);

  const handleOnAdd = () => {
    const values = form.getFieldsValue();
    const { cost, name, effectiveDate, ...restData } = values;
    dispatch(
      addProcessingLineItem({
        processingAppId: applicationId,
        type: ProcessingLineType.TARGET,
        info: {
          ...restData,
          effectiveDate: effectiveDate ? moment(effectiveDate).format("YYYY-MM-DD") : undefined,
        },
      }),
    );
  };

  const handleOnUpdate = () => {
    if (editData) {
      const values = form.getFieldsValue();
      const { cost, name, effectiveDate, ...restData } = values;

      dispatch(
        updateProcessingLineItem({
          processingAppId: applicationId,
          id: editData.id,
          type: ProcessingLineType.TARGET,
          info: {
            ...restData,
            effectiveDate: effectiveDate ? moment(effectiveDate).format("YYYY-MM-DD") : undefined,
          },
        }),
      );
    }
  };

  const resetFormValues = () => {
    dispatch(resetProductStockDetail());
    form.resetFields();
  };

  const handleOnclose = () => {
    resetFormValues();
    onClose();
  };

  useEffect(() => {
    // 編輯資料處理
    if (editData) {
      const formData = {
        ...editData,
        storageType:
          storageTypeOptions.find((option) => option.value === editData.storageType)?.value || editData.storageType,
      };
      form.setFieldsValue(formData);
      dispatch(fetchProductStockDetail(editData.no));
    }
  }, [form, editData, dispatch]);

  useEffect(() => {
    // fetch商品品號資料處理
    if (productStockDetail) {
      form.setFieldsValue({
        name: productStockDetail.name,
        cost: productStockDetail.cost,
      });
    }
  }, [form, productStockDetail]);

  return (
    <PopupAddAndUpdateModal
      form={form}
      onClose={handleOnclose}
      onAdd={handleOnAdd}
      onUpdate={handleOnUpdate}
      initialValues={editData ? form.getFieldsValue() : undefined}
      width={520}
      padding={20}
      AddText="建立"
      updateText="確定"
      title="加工品項"
    >
      <>
        <Form.Item
          label="商品品號"
          name="no"
          labelCol={{ span: 4 }}
          hasFeedback
          validateStatus={noCheckState}
          help={isNoStockChecked || undefined}
          rules={[{ required: true, message: "請輸入商品品號" }]}
        >
          <Input
            allowClear
            onChange={(e) => {
              fetchNoInfo(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="商品名稱"
          name="name"
          labelCol={{ span: 4 }}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("查無對應商品，請確認商品品號碼是否正確"));
              },
            }),
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="倉別"
          name="storageType"
          labelCol={{ span: 4 }}
          rules={[{ required: true, message: "請選擇倉別" }]}
        >
          <Select placeholder="請選擇">
            {storageTypeOptions?.map((option) => (
              <Select.Option key={option.name} value={option.value}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="批號" name="batch" labelCol={{ span: 4 }}>
          <Input allowClear />
        </Form.Item>
        <Form.Item label="成本" name="cost" labelCol={{ span: 4 }}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="應出數量"
          name="qty"
          labelCol={{ span: 4 }}
          rules={[{ required: true, message: "請輸入應出數量" }]}
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
      </>
    </PopupAddAndUpdateModal>
  );
};

// 來源品項 Modal
const SourceLineItemAddModal: FC<Props> = (props) => {
  const { onClose, editData } = props;
  const dispatch = useAppDispatch();
  const { id: applicationIdStr } = useParams();
  const applicationId = Number(applicationIdStr);
  const [form] = Form.useForm();
  const { isFetching, productStockDetail, isNoStockChecked } = useSelector(ProcessingApplicationState);
  const [storageTypeOpts, setStorageTypeOptions] = useState<typeof storageTypeOptions>();
  const [isEditInit, setIsEditInit] = useState<boolean>(true);

  const noCheckState = useMemo(() => {
    if (isFetching) return "validating";
    return isNoStockChecked ? "error" : undefined;
  }, [isFetching, isNoStockChecked]);

  const fetchNoInfo = useDebounce((value: string) => {
    if (value) {
      dispatch(fetchProductStockDetail(value));
    } else {
      dispatch(resetProductStockDetail());
      form.resetFields();
    }
  }, 1000);

  const handelEffectiveDateBatch = (values: SelectValue) => {
    const [effectiveDate, batch] = JSON.parse(values as string);
    const storeType = form.getFieldValue("storageType");
    const effectiveDateBatchOptions = productStockDetail?.stocks[storeType];
    const target = effectiveDateBatchOptions?.find(
      (option: StockDetail) => option.effectiveDate === effectiveDate && option.batch === batch,
    );
    form.setFieldsValue({
      effectiveDate: moment(effectiveDate),
      batch,
      canSalesQty: target?.canSalesQty,
    });
  };

  const handleOnAdd = () => {
    const values = form.getFieldsValue();
    const { name, cost, canSalesQty, effectiveDateBatch, ...resetValues } = values;
    const [effectiveDate, batch] = JSON.parse(effectiveDateBatch as string);
    dispatch(
      addProcessingLineItem({
        processingAppId: applicationId,
        type: ProcessingLineType.SOURCE,
        info: {
          ...resetValues,
          effectiveDate,
          batch,
        },
      }),
    );
  };

  const handleOnUpdate = () => {
    if (editData) {
      const values = form.getFieldsValue();
      const { name, cost, canSalesQty, effectiveDateBatch, ...resetValues } = values;
      const [effectiveDate, batch] = JSON.parse(effectiveDateBatch as string);
      dispatch(
        updateProcessingLineItem({
          processingAppId: applicationId,
          id: editData.id,
          type: ProcessingLineType.SOURCE,
          info: {
            ...resetValues,
            effectiveDate,
            batch,
          },
        }),
      );
    }
  };
  const resetFormValues = () => {
    dispatch(resetProductStockDetail());
    form.resetFields();
  };

  const handleOnclose = () => {
    resetFormValues();
    onClose();
  };
  useEffect(() => {
    // 編輯資料處理 -> 取得商品品號資料
    if (editData && isEditInit) {
      dispatch(fetchProductStockDetail(editData.no));
    }
  }, [form, editData, dispatch, isEditInit]);

  useEffect(() => {
    // fetch商品品號資料處理：1. 初始編輯資料處理 2. 輸入商品品號，fetch品號資料
    if (!productStockDetail) return;

    //  生成倉別選項
    const { stocks } = productStockDetail;
    if (stocks) {
      const storeTypeOptions = Object.keys(stocks).map(
        (key) => storageTypeOptions.find((option) => option.key === key) || { key, name: key, value: key },
      );
      setStorageTypeOptions(storeTypeOptions);
    }

    // 2. 輸入商品品號，fetch品號資料處理 -> 新增(!editData) or 非初始編輯資料處理(!isEditInit)
    if (!editData || !isEditInit) {
      form.setFieldsValue({
        name: productStockDetail.name,
        cost: productStockDetail.cost,
        storageType: undefined,
        effectiveDateBatch: undefined,
        canSalesQty: undefined,
      });
      return;
    }

    // 1. 初始編輯資料處理 ->
    if (editData && isEditInit) {
      const { storageType, effectiveDate, batch } = editData;
      const selectedStorageType = productStockDetail.stocks[storageType]?.find(
        (stock) => stock.effectiveDate === effectiveDate && stock.batch === batch,
      );
      const effectiveDateBatch = selectedStorageType ? JSON.stringify([effectiveDate, batch]) : undefined;
      const formData = {
        ...editData,
        storageType: storageTypeOptions.find((option) => option.value === editData.storageType)?.value || undefined,
        effectiveDateBatch,
        canSalesQty: selectedStorageType?.canSalesQty || undefined,
      };
      form.setFieldsValue(formData);
      setIsEditInit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, form, productStockDetail]);

  return (
    <PopupAddAndUpdateModal
      form={form}
      onClose={handleOnclose}
      onAdd={handleOnAdd}
      onUpdate={handleOnUpdate}
      initialValues={editData ? form.getFieldsValue() : undefined}
      width={520}
      padding={20}
      AddText="建立"
      updateText="確定"
      title="來源品項"
    >
      <>
        <Form.Item
          label="商品品號"
          name="no"
          labelCol={{ span: 5 }}
          rules={[{ required: true, message: "請輸入商品品號" }]}
          hasFeedback
          validateStatus={noCheckState}
          help={isNoStockChecked || undefined}
        >
          <Input
            onChange={(e) => {
              fetchNoInfo(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="商品名稱"
          name="name"
          labelCol={{ span: 5 }}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("查無對應商品，請確認商品品號碼是否正確"));
              },
            }),
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="倉別"
          name="storageType"
          labelCol={{ span: 5 }}
          rules={[{ required: true, message: "請選擇倉別" }]}
        >
          <Select
            placeholder="請選擇"
            onChange={(e) => {
              form.setFieldsValue({ effectiveDateBatch: undefined, canSalesQty: undefined });
            }}
          >
            {storageTypeOpts?.map((option) => (
              <Select.Option key={option.name} value={option.value}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const storeType = getFieldValue("storageType");
            const effectiveDateBatchOptions = productStockDetail?.stocks[storeType];
            return (
              <Form.Item
                name="effectiveDateBatch"
                label="效期_批號"
                labelCol={{ span: 5 }}
                rules={[{ required: true, message: "請選擇效期_批號" }]}
                shouldUpdate
              >
                <Select placeholder="請選擇" onSelect={handelEffectiveDateBatch}>
                  {effectiveDateBatchOptions?.map((option: StockDetail) => {
                    const { effectiveDate = "", batch = "" } = option;
                    const value = JSON.stringify([effectiveDate, batch]);
                    const effectiveDateBatch = `${effectiveDate}_${batch}`;
                    return (
                      <Select.Option key={effectiveDateBatch} value={value}>
                        {effectiveDateBatch}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item label="成本" name="cost" labelCol={{ span: 5 }}>
          <Input disabled />
        </Form.Item>
        <Form.Item label="庫存數" name="canSalesQty" labelCol={{ span: 5 }}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="應出數量"
          name="qty"
          labelCol={{ span: 5 }}
          rules={[
            { required: true, message: "請輸入應出數量" },
            ({ getFieldsValue }) => ({
              validator(rule, values) {
                const formValue = getFieldsValue();
                const { canSalesQty } = formValue;
                if (canSalesQty && values > canSalesQty) {
                  return Promise.reject(new Error("應出數量不得大於庫存數"));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber min={1} style={{ width: "100%" }} />
        </Form.Item>
      </>
    </PopupAddAndUpdateModal>
  );
};
