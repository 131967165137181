import { ExclamationCircleOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import specialShipmentApi, { ProductWarehouseStockItem, SSProductItem } from "@api/sharing/specialShipmentApi";
import PopupBackground from "@component/PopupBackground";
import IntegratedTable from "@component/Table/IntegratedTable";
import {
  createSpecialShipmentProduct,
  deleteSpecialShipmentProduct,
  fetchSpecialShipmentProductList,
  specialShipmentState,
  updateProductParams,
  updateSpecialShipmentProduct,
} from "@redux/specialShipmentSlice";
import { Button, Col, DatePicker, Form, FormInstance, Input, InputNumber, Modal, Row, Select, Space, Spin } from "antd";
import { ValidateStatus } from "antd/es/form/FormItem";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useDebounce from "@hooks/useDebounce";
import { StyledSection, TableWrapper } from "src/styles/common";
import styled from "styled-components";
import fontStyle from "src/styles/fontStyle";
import moment, { Moment } from "moment";
import storageTypeOptions from "@constant/StorageType";

const PopupContent = styled.div`
  max-width: 70vw;
  padding: 20px;
  background: ${({ theme }) => theme.colorNeutral100};
  max-height: 90vh;
  overflow: auto;
  width: 600px;
  && .ant-form-item-required.ant-form-item-no-colon::before {
    display: none;
  }
  && .ant-form-item-required.ant-form-item-no-colon::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
`;

const Notice = styled.span`
  margin-top: 5px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colorNeutral500};
  ${fontStyle("14px", "22px")};
`;

const CustomDatePicker = styled(DatePicker)`
  width: 100%;
`;

export default function SpecialShipmentProduct() {
  const dispatch = useDispatch();
  const { shipmentId: shipmentIdStr } = useParams();
  const [modalForm] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [editProduct, setEditProduct] = useState<SSProductItem | null>(null);
  const shipmentId = Number(shipmentIdStr);

  const { isFetching, specialShipmentDetail, specialShipmentProductList, productParams } = useSelector(
    specialShipmentState,
  );
  const isTCat = specialShipmentDetail?.warehouseCode === "TCAT";

  const { results: productResults, count } = specialShipmentProductList;
  const canEdit = specialShipmentDetail?.canEdit || false;

  const handleOnSubmitProduct = (type: "create" | "update", values: any, productId?: number) => {
    if (type === "create") {
      dispatch(createSpecialShipmentProduct({ shipmentId, payload: values }));
    }
    if (type === "update" && productId) {
      dispatch(updateSpecialShipmentProduct({ shipmentId, productId, payload: values }));
    }
  };

  const TCatColumns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "SKU(品號)",
      dataIndex: "sku",
    },
    {
      title: "商品名稱",
      dataIndex: "productName",
    },
    {
      title: "成本",
      dataIndex: "cost",
    },
    {
      title: "庫存量",
      dataIndex: "stock",
    },
    {
      title: "商品效期",
      dataIndex: "effectiveDate",
    },
    {
      title: "商品數量(件)",
      dataIndex: "qty",
    },
    {
      title: "",
      dataIndex: "id",
      fixed: "right",
      render: (id: number, record: any) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setEditProduct(record);
                setShowModal(true);
              }}
              disabled={!canEdit}
            >
              編輯
            </Button>
            <Button
              type="link"
              onClick={() => {
                Modal.confirm({
                  title: "確定要刪除此商品嗎?",
                  icon: <ExclamationCircleOutlined />,
                  okText: "是",
                  cancelText: "否",
                  onOk() {
                    dispatch(deleteSpecialShipmentProduct({ shipmentId, productId: id }));
                  },
                });
              }}
              disabled={!canEdit}
            >
              刪除
            </Button>
          </>
        );
      },
    },
  ];

  const RenTrapColumns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "SKU(品號)",
      dataIndex: "sku",
    },
    {
      title: "barcode",
      dataIndex: "barcode",
    },
    {
      title: "商品名稱",
      dataIndex: "productName",
    },
    {
      title: "成本",
      dataIndex: "cost",
    },
    {
      title: "倉別",
      dataIndex: "warehouseStorageName",
    },
    {
      title: "商品效期",
      dataIndex: "effectiveDate",
    },
    {
      title: "批號",
      dataIndex: "batch",
    },
    {
      title: "庫存量",
      dataIndex: "stock",
    },
    {
      title: "商品數量(件)",
      dataIndex: "qty",
    },
    {
      title: "",
      dataIndex: "id",
      fixed: "right",
      render: (id: number, record: any) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setEditProduct(record);
                setShowModal(true);
              }}
              disabled={!canEdit}
            >
              編輯
            </Button>
            <Button
              type="link"
              onClick={() => {
                Modal.confirm({
                  title: "確定要刪除此商品嗎?",
                  icon: <ExclamationCircleOutlined />,
                  okText: "是",
                  cancelText: "否",
                  onOk() {
                    dispatch(deleteSpecialShipmentProduct({ shipmentId, productId: id }));
                  },
                });
              }}
              disabled={!canEdit}
            >
              刪除
            </Button>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(fetchSpecialShipmentProductList(shipmentId));
  }, [dispatch, shipmentId]);

  return (
    <StyledSection>
      <Row justify="space-between" style={{ marginBottom: 21 }} align="middle">
        <Col span={12}>
          <Space>
            <Button
              type="primary"
              icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
              onClick={() => {
                setShowModal(true);
              }}
              disabled={!canEdit}
            >
              新增商品
            </Button>
          </Space>
        </Col>
      </Row>
      <TableWrapper>
        <IntegratedTable
          isPagination
          paginationConfig={{
            totalCount: count,
            pageSizeOptions: [50, 100, 200],
            offset: productParams.offset,
            limit: productParams.limit,
            fetchList: () => {
              dispatch(fetchSpecialShipmentProductList(shipmentId));
            },
            updateFilterParams: (filter) => {
              dispatch(updateProductParams(filter));
            },
            filterParams: productParams,
          }}
          dataSource={productResults}
          loading={isFetching}
          columns={isTCat ? TCatColumns : RenTrapColumns}
          scroll={{ x: "max-content" }}
          rowKey="sku"
        />
      </TableWrapper>
      {showModal && (
        <ProductFormModal
          form={modalForm}
          data={editProduct}
          warehouseCode={specialShipmentDetail?.warehouseCode}
          storageType={specialShipmentDetail?.condition}
          isTCat={isTCat}
          onClose={() => {
            setShowModal(false);
            setEditProduct(null);
          }}
          onSubmit={handleOnSubmitProduct}
        />
      )}
    </StyledSection>
  );
}

type FormProps = {
  form: FormInstance<any>;
  data: SSProductItem | null;
  warehouseCode?: string;
  storageType?: string;
  onClose: () => void;
  isTCat: boolean;
  onSubmit: (type: "create" | "update", values: any, productId?: number) => void;
};
const ProductFormModal = (props: FormProps) => {
  const { isTCat } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return isTCat ? <TCatProductFormModal {...props} /> : <RenTrapProductFormModal {...props} />;
};

const TCatProductFormModal = (props: Omit<FormProps, "isTCat">) => {
  const { form, data, warehouseCode, onClose, storageType, onSubmit } = props;
  const [skuError, setSkuError] = useState<{
    validateStatus: ValidateStatus;
    errorMsg: string | null;
  }>({ validateStatus: "", errorMsg: null });
  const canSalesQtyFormValue = Form.useWatch("canSalesQty", form);

  const fetchProductDetailBySku = useDebounce(async (sku: string) => {
    if (sku && warehouseCode) {
      setSkuError({ validateStatus: "validating", errorMsg: "" });
      try {
        const [info, stock] = await Promise.all([
          specialShipmentApi.fetchProductWarehouseInfo(sku, warehouseCode),
          specialShipmentApi.fetchProductWarehouseStockList(sku, warehouseCode),
        ]);
        if (info.length === 0) throw new Error("查無此商品");
        if (stock.length === 0) throw new Error("查無商品庫存");
        const resProductStock = stock[0].stockInfo?.find((item) => item.storageType === storageType);
        form.setFieldsValue({
          productName: info[0].productName,
          weightedCost: info[0].weightedCost,
          canSalesQty: resProductStock?.canSalesQty,
          qty: undefined,
        });
        setSkuError({ validateStatus: "success", errorMsg: null });
      } catch (e) {
        const error = e as Error;
        setSkuError({ validateStatus: "error", errorMsg: error.message });
        form.setFieldsValue({
          productName: undefined,
          weightedCost: undefined,
          qty: undefined,
        });
      }
    }
  }, 1000);

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };

  const handleOnTCatSubmit = (values: any) => {
    const { sku, effectiveDate, qty } = values;
    const payload = {
      sku,
      storageType,
      effectiveDate: effectiveDate?.format("YYYY-MM-DD"),
      qty,
      batch: null,
    };
    const type = data ? "update" : "create";
    onSubmit(type, payload, data?.id);
    handleOnClose();
  };

  const disabledDate = (currentDate: Moment) => {
    // Can not select days before today and today
    return currentDate && currentDate < moment().endOf("day");
  };
  useEffect(() => {
    if (!data) return;
    form.setFieldsValue({
      sku: data.sku,
      barcode: data.barcode,
      productName: data.productName,
      weightedCost: data.cost,
      qty: data.qty,
      effectiveDate: data.effectiveDate,
      canSalesQty: data.stock,
    });
    setSkuError({ validateStatus: "success", errorMsg: null });
  }, [data, form]);
  return (
    <PopupBackground close={() => {}}>
      <PopupContent>
        <Form form={form} colon={false} labelCol={{ span: 8 }} labelAlign="left" onFinish={handleOnTCatSubmit}>
          <Spin spinning={false}>
            <Row>
              <Col span={16}>
                <Form.Item
                  label="SKU(品號)"
                  name="sku"
                  labelAlign="left"
                  hasFeedback
                  rules={[
                    { required: true, message: "" },
                    {
                      validator: () => {
                        return skuError.validateStatus === "success"
                          ? Promise.resolve()
                          : Promise.reject(skuError.errorMsg);
                      },
                    },
                  ]}
                  validateStatus={skuError.validateStatus}
                  help={skuError.errorMsg || ""}
                >
                  <Input onChange={(e) => fetchProductDetailBySku(e.target.value)} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item label="商品名稱" name="productName" labelAlign="left">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col>
                <Notice>*輸入SKU後自動帶入</Notice>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item label="成本" name="weightedCost" labelAlign="left">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col>
                <Notice>*輸入SKU後自動帶入</Notice>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item label="庫存量" name="canSalesQty" labelAlign="left">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col>
                <Notice>*輸入SKU後自動帶入</Notice>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item label="商品效期" name="effectiveDate" labelAlign="left">
                  <CustomDatePicker disabledDate={disabledDate} allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item
                  label="商品數量(pcs)"
                  name="qty"
                  labelAlign="left"
                  validateTrigger="onBlur"
                  rules={[
                    { required: true, message: "" },
                    {
                      validator: (rule, value) => {
                        if (!value) return Promise.reject(new Error(""));
                        if (value && value <= canSalesQtyFormValue) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("商品數量不可大於庫存量"));
                      },
                    },
                  ]}
                >
                  <InputNumber min={1} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Space>
                <Button type="default" onClick={() => handleOnClose()}>
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  確認
                </Button>
              </Space>
            </Row>
          </Spin>
        </Form>
      </PopupContent>
    </PopupBackground>
  );
};

const RenTrapProductFormModal = (props: Omit<FormProps, "isTCat">) => {
  const { form, data, warehouseCode, onClose, onSubmit } = props;
  const [skuError, setSkuError] = useState<{
    validateStatus: ValidateStatus;
    errorMsg: string | null;
  }>({ validateStatus: "", errorMsg: null });

  const [stockList, setStockList] = useState<StockList>({});
  const [effectiveDateObj, setEffectiveDateObj] = useState<StockList>({});
  const [batchOptions, setBatchOptions] = useState<{ label: string; value: string }[]>([]);
  const [editInit, setEditInit] = useState(false);
  const canSalesQtyFormValue = Form.useWatch("canSalesQty", form);

  const fetchProductDetailBySku = useDebounce(async (sku: string) => {
    if (sku && warehouseCode) {
      setSkuError({ validateStatus: "validating", errorMsg: "" });
      try {
        const [info, stock] = await Promise.all([
          specialShipmentApi.fetchProductWarehouseInfo(sku, warehouseCode),
          specialShipmentApi.fetchProductWarehouseStockList(sku, warehouseCode),
        ]);
        if (info.length === 0) throw new Error("查無此商品");
        if (stock.length === 0) throw new Error("查無商品庫存");
        form.setFieldsValue({
          barcode: info[0].barcode,
          productName: info[0].productName,
          weightedCost: info[0].weightedCost,
        });
        setSkuError({ validateStatus: "success", errorMsg: null });
        const obj = transToObj(stock[0].stockInfo, "storageType");
        setStockList(obj);
      } catch (e) {
        const error = e as Error;
        setSkuError({ validateStatus: "error", errorMsg: error.message });
        form.setFieldsValue({
          productName: undefined,
          weightedCost: undefined,
          qty: undefined,
        });
      }
    }
  }, 1000);

  const handleStockChange = (value: string) => {
    const stockData = stockList[value];
    const obj = transToObj(stockData, "effectiveDate");
    setEffectiveDateObj(obj);
    form.setFieldsValue({ effectiveDate: undefined, batch: undefined });
  };

  const handleEffectiveDateChange = (value: string) => {
    const batchList = effectiveDateObj[value];
    setBatchOptions(batchList.map((batch) => ({ label: batch.batch || "無批號", value: batch.batch || "-1" }))); // 沒有批號的情況就用-1
    form.setFieldsValue({ batch: undefined });
  };

  const handleOnBatchChange = (value: string) => {
    const effectiveDate = form.getFieldValue("effectiveDate");
    const batch = value === "-1" ? null : value;
    const batchData = effectiveDateObj[effectiveDate].find((item) => item.batch === batch);
    form.setFieldsValue({ canSalesQty: batchData?.canSalesQty });
  };

  const transToObj = (list: Array<any>, key: string) => {
    const obj = {} as StockList;
    list.forEach((item) => {
      const objKey = item[key];
      if (objKey === null) return;
      if (!obj[objKey]) {
        obj[objKey] = [];
      }
      obj[objKey].push(item);
    });
    return obj;
  };

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };

  const handleOnRenTrapSubmit = (values: any) => {
    const { sku, effectiveDate, qty, batch, storageType } = values;
    const payload = {
      sku,
      storageType,
      effectiveDate,
      qty,
      batch: batch === "-1" ? "" : batch,
    };
    const type = data ? "update" : "create";
    onSubmit(type, payload, data?.id);
    handleOnClose();
  };

  // 編輯init的時候，用sku去撈商品資訊
  useEffect(() => {
    if (!data) return;
    if (editInit) return;
    fetchProductDetailBySku(data.sku);
    form.setFieldsValue({
      sku: data.sku,
      qty: data.qty,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, editInit, form]);

  // 編輯init的時候，要依序把stockList, effectiveDateObj, batchOptions都設定好
  useEffect(() => {
    if (editInit) return;
    if (!data || Object.keys(stockList).length === 0) return;
    handleStockChange(data.warehouseStorageType);
    form.setFieldsValue({
      storageType: data.warehouseStorageType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, editInit, form, stockList]);
  useEffect(() => {
    if (editInit) return;
    if (!data || Object.keys(effectiveDateObj).length === 0) return;
    handleEffectiveDateChange(data.effectiveDate);
    form.setFieldsValue({
      effectiveDate: data.effectiveDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, editInit, form, effectiveDateObj]);
  useEffect(() => {
    if (editInit) return;
    if (!data || Object.keys(batchOptions).length === 0) return;
    handleOnBatchChange(data.batch);
    form.setFieldsValue({
      batch: data.batch,
    });
    setEditInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, editInit, form, batchOptions]);

  return (
    <PopupBackground close={() => {}}>
      <PopupContent>
        <Form form={form} colon={false} labelCol={{ span: 8 }} labelAlign="left" onFinish={handleOnRenTrapSubmit}>
          <Spin spinning={false}>
            <Row>
              <Col span={16}>
                <Form.Item
                  label="SKU(品號)"
                  name="sku"
                  labelAlign="left"
                  hasFeedback
                  rules={[
                    { required: true, message: "" },
                    {
                      validator: () => {
                        return skuError.validateStatus === "success"
                          ? Promise.resolve()
                          : Promise.reject(skuError.errorMsg);
                      },
                    },
                  ]}
                  validateStatus={skuError.validateStatus}
                  help={skuError.errorMsg || ""}
                >
                  <Input onChange={(e) => fetchProductDetailBySku(e.target.value)} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item label="barcode" name="barcode" labelAlign="left">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col>
                <Notice>*輸入SKU後自動帶入</Notice>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item label="商品名稱" name="productName" labelAlign="left">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col>
                <Notice>*輸入SKU後自動帶入</Notice>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item label="成本" name="weightedCost" labelAlign="left">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col>
                <Notice>*輸入SKU後自動帶入</Notice>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item label="倉別" name="storageType" labelAlign="left" rules={[{ required: true, message: "" }]}>
                  <Select
                    options={Object.keys(stockList).map((storage) => {
                      const storageDate = storageTypeOptions.find((item) => item.key === storage);
                      return { label: storageDate?.name, value: storageDate?.value };
                    })}
                    onChange={handleStockChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item
                  label="商品效期"
                  name="effectiveDate"
                  labelAlign="left"
                  rules={[{ required: true, message: "" }]}
                >
                  <Select
                    placeholder="請先選擇倉別"
                    disabled={!form.getFieldValue("storageType")}
                    options={Object.keys(effectiveDateObj).map((date) => ({ label: date, value: date }))}
                    onChange={handleEffectiveDateChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item label="批號" name="batch" labelAlign="left" rules={[{ required: true, message: "" }]}>
                  <Select
                    placeholder="請先選擇商品效期"
                    disabled={!form.getFieldValue("effectiveDate")}
                    options={batchOptions}
                    onChange={handleOnBatchChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item label="庫存量" name="canSalesQty" labelAlign="left">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item
                  label="商品數量(pcs)"
                  name="qty"
                  labelAlign="left"
                  rules={[
                    { required: true, message: "" },
                    {
                      validator: (rule, value) => {
                        if (!value) return Promise.reject(new Error(""));
                        if (value && value <= canSalesQtyFormValue) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("商品數量不可大於庫存量"));
                      },
                    },
                  ]}
                >
                  <InputNumber min={1} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Space>
                <Button type="default" onClick={() => handleOnClose()}>
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  確認
                </Button>
              </Space>
            </Row>
          </Spin>
        </Form>
      </PopupContent>
    </PopupBackground>
  );
};

type StockList = {
  [key: string]: ProductWarehouseStockItem[];
};
