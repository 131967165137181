import { RootState } from "@redux/rootReducer";
import { fetchContractVendors, fetchPurchaseInfo } from "@redux/warehouseSlice";
import { Button, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import IntoWarehouseForm from "./IntoWarehouseForm";
import WarehouseInfo from "./WarehouseInfo";
import WarehouseProduct from "./WarehouseProduct";

const Wrapper = styled.div`
  padding: 17px 20px 17px 25px;
`;

const InfoWrapper = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 8px 20px 8px 12px;
  position: relative;
`;

const ButtonRow = styled(Row)`
  position: absolute;
  right: 20px;
  top: 9px;
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const warehouseSlice = (state: RootState) => state.warehouseSlice;

export default function IntoWarehouseEdit() {
  const { warehouseId, showDestroy } = useParams();
  const dispatch = useDispatch();
  const { purchaseInfo } = useSelector(warehouseSlice);

  const [activeKey, setActiveKey] = useState<string>("edit");
  const [openForm, setOpenForm] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchPurchaseInfo({ purchaseId: parseInt(warehouseId, 10), showDestroy }));
  }, [dispatch, warehouseId, showDestroy]);

  useEffect(() => {
    dispatch(fetchContractVendors());
  }, [dispatch]);

  return (
    <Wrapper>
      <PageTitle title={`進倉 - ${purchaseInfo?.stoNumber}`} />
      <InfoWrapper>
        <Tabs type="card" onChange={(key) => setActiveKey(key)}>
          <Tabs.TabPane tab="編輯進倉單" key="edit">
            <WarehouseInfo purchase={purchaseInfo} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="進倉商品" key="product">
            <WarehouseProduct />
          </Tabs.TabPane>
        </Tabs>
        {activeKey === "edit" && (
          <ButtonRow align="middle">
            <CustomButton
              disabled={purchaseInfo && purchaseInfo.matoLink === null}
              onClick={() => window.open(purchaseInfo?.matoLink)}
            >
              列印麥頭
            </CustomButton>
            <Button
              type="primary"
              disabled={!!purchaseInfo?.destroyedAt || (purchaseInfo && purchaseInfo.matoLink !== null)}
              onClick={() => setOpenForm(true)}
            >
              編輯
            </Button>
          </ButtonRow>
        )}
        {openForm && <IntoWarehouseForm purchaseId={parseInt(warehouseId, 10)} close={() => setOpenForm(false)} />}
      </InfoWrapper>
    </Wrapper>
  );
}
