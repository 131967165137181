import { ExclamationCircleOutlined, FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { SpecialShipmentListParams } from "@api/sharing/specialShipmentApi";
import PageTitle from "@component/PageTitle";
import IntegratedTable from "@component/Table/IntegratedTable";
import Filter from "@page/SpecialShipment/Filter";
import {
  deleteSpecialShipment,
  fetchSpecialShipmentList,
  resetSlice,
  setOpenShipmentFormType,
  specialShipmentState,
  updateListParams,
} from "@redux/specialShipmentSlice";

import { Button, Col, Modal, Row, Space } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { StyledSection, TableWrapper } from "src/styles/common";
import {
  approvalStatusOptions,
  OrderStatus,
  orderStatusOptions,
  SpecialShipmentPurpose,
  specialShipmentPurposeOptions,
} from "./specialShipmentConstants";

export default function SpecialShipmentListPage() {
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = useState(true);

  const { isFetching, specialShipmentList, specialShipmentListParams } = useSelector(specialShipmentState);
  const { results: specialShipmentListResults, count } = specialShipmentList;
  const handleOnDeleteItem = (shipmentId: number, orderNumber: number) => {
    Modal.confirm({
      title: `你確定要作廢這筆資料：${orderNumber}`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteSpecialShipment(shipmentId));
      },
      okText: "確定",
      cancelText: "取消",
    });
  };

  const checkDisabledRow = (record: any) => ({ className: record.destroyedAt ? "ur-disabled-text" : "" });

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { column, field, order } = sorter;
    if (column) {
      const snakeField = field.replace(/([A-Z])/g, "_$1").toLowerCase();
      const ordering = order === "ascend" ? "" : "-";
      dispatch(updateListParams({ ...specialShipmentListParams, ordering: `${ordering}${snakeField}` }));
    } else {
      dispatch(updateListParams({ ...specialShipmentListParams, ordering: undefined }));
    }
  };
  const columns: ColumnsType<any> = [
    {
      title: "訂單編號",
      dataIndex: "orderNumber",
    },
    {
      title: "出庫倉庫",
      dataIndex: "warehouseName",
    },
    {
      title: "收件人",
      dataIndex: "receiverName",
    },
    {
      title: "審核狀態",
      dataIndex: "approvalStatus",
      align: "center",
      render: (state, record) =>
        record.canSubmit ? (
          "未送出申請"
        ) : (
          <p>{approvalStatusOptions.find((option) => option.value === state)?.label}</p>
        ),
    },
    {
      title: "訂單狀態",
      dataIndex: "status",
      align: "center",
      render: (state: OrderStatus) => <p>{orderStatusOptions.find((option) => option.value === state)?.label}</p>,
    },
    {
      title: "出貨緣由",
      dataIndex: "purpose",
      render: (state: SpecialShipmentPurpose) => (
        <p>{specialShipmentPurposeOptions.find((option) => option.value === state)?.label}</p>
      ),
    },
    {
      title: "預期拋單日期",
      dataIndex: "estDeployedDate",
      sorter: true,
      align: "center",
      render: (estDeployedDate: string) => estDeployedDate || "N/A",
    },
    {
      title: "實際出貨時間",
      dataIndex: "shippingAt",
      sorter: true,
      render: (shippingAt: string) => shippingAt || "N/A",
      align: "center",
    },
    {
      title: "出貨商品總數",
      dataIndex: "totalQty",
      render: (totalQty: string) => totalQty || "N/A",
    },
    {
      title: "總成本",
      dataIndex: "totalCost",
      render: (totalCost: string) => totalCost || "N/A",
    },
    {
      title: "建立人員",
      dataIndex: "staffName",
    },
    {
      title: "建立時間",
      dataIndex: "createdAt",
    },
    {
      title: "",
      dataIndex: "id",
      fixed: "right",
      render: (id: number, record: any) => {
        return (
          <Link to={`/special-shipment/edit/${id}`} target="_blank">
            {record.canEdit ? "編輯" : "檢視"}
          </Link>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      fixed: "right",
      render: (shipmentId: number, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              handleOnDeleteItem(shipmentId, record.orderNumber);
            }}
            disabled={!!record.destroyedAt || !record.canCancel}
          >
            作廢
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchSpecialShipmentList(specialShipmentListParams));
  }, [dispatch, specialShipmentListParams]);

  useEffect(() => {
    return () => {
      dispatch(resetSlice());
    };
  }, [dispatch]);

  return (
    <StyledSection>
      <PageTitle title="特例出貨" />
      <Row justify="space-between" style={{ marginBottom: 21 }} align="middle">
        <Col span={12}>
          <Space>
            <Button
              type="primary"
              icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
              onClick={() => {
                dispatch(setOpenShipmentFormType("create"));
              }}
            >
              新增出貨單
            </Button>
            <Button
              icon={<FilterTwoTone />}
              onClick={() => {
                setOpenFilter(!openFilter);
              }}
            >
              篩選
            </Button>
          </Space>
        </Col>
      </Row>
      <Filter open={openFilter} />
      <TableWrapper>
        <IntegratedTable
          isPagination
          paginationConfig={{
            totalCount: count,
            pageSizeOptions: [50, 100, 200],
            offset: specialShipmentListParams.offset,
            limit: specialShipmentListParams.limit,
            fetchList: () => {
              dispatch(fetchSpecialShipmentList(specialShipmentListParams));
            },
            updateFilterParams: (filter: SpecialShipmentListParams) => {
              updateListParams(filter);
            },
            filterParams: specialShipmentListParams,
          }}
          dataSource={specialShipmentListResults}
          loading={isFetching}
          columns={columns}
          scroll={{ x: "max-content" }}
          onRow={checkDisabledRow}
          onChange={handleTableChange}
        />
      </TableWrapper>
    </StyledSection>
  );
}
