import PageTitle from "@component/PageTitle";
import { Button, Tabs } from "antd";
import React, { useEffect } from "react";
import { FlexBox, TabWrapper } from "src/styles/common";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchIWApplicationDetails, IWBrandState, setEditModal } from "@redux/brandOperation/intoWarehouseBrandSlice";
import EditTab from "./EditTab";
import ProductTab from "./ProductTab";

const IntoWarehouseReview = () => {
  const dispatch = useDispatch();
  const { id: applicationId } = useParams();
  const { editModal, intoWarehouseDetails } = useSelector(IWBrandState);

  const operations = (
    <FlexBox>
      <Button onClick={() => window.open(intoWarehouseDetails?.matoLink)} disabled={!intoWarehouseDetails?.matoLink}>
        列印麥頭
      </Button>
      <Button
        type="primary"
        onClick={() => dispatch(setEditModal(!editModal))}
        disabled={!!intoWarehouseDetails?.stockinDate}
      >
        編輯
      </Button>
    </FlexBox>
  );

  useEffect(() => {
    if (applicationId) {
      dispatch(fetchIWApplicationDetails(Number(applicationId)));
    }
  }, [applicationId, dispatch]);
  return (
    <>
      <TabWrapper>
        <PageTitle title="品運進貨" />
        <Tabs type="card" tabBarExtraContent={operations} defaultActiveKey="editType">
          <Tabs.TabPane tab="編輯進倉單" key="editType">
            <EditTab intoWarehouseDetails={intoWarehouseDetails} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="進倉商品" key="productType">
            {intoWarehouseDetails && <ProductTab canDeploy={!intoWarehouseDetails.stoNumber} />}
          </Tabs.TabPane>
        </Tabs>
      </TabWrapper>
    </>
  );
};
export default IntoWarehouseReview;
