export enum OrderStatus {
  CANCEL = 0,
  WAITING_DEPLOY = 10,
  PROCESSING = 20,
  PICKING = 30,
  OUT_OF_STOCK = 40,
  ALREADY_SHIPPED = 50,
  SHIPPING_ABNORMAL = 60,
  FINISH = 70,
}

export const OrderStatusLabel: { [key in OrderStatus]: string } = {
  [OrderStatus.CANCEL]: "取消訂單",
  [OrderStatus.WAITING_DEPLOY]: "待拋單",
  [OrderStatus.PROCESSING]: "已拋單/待處理",
  [OrderStatus.PICKING]: "撿貨處理中",
  [OrderStatus.OUT_OF_STOCK]: "庫存不足",
  [OrderStatus.ALREADY_SHIPPED]: "配送中",
  [OrderStatus.SHIPPING_ABNORMAL]: "配送異常",
  [OrderStatus.FINISH]: "已送達",
};
