const translateErrors = (errorObject: any) => {
  if (!errorObject) return [];
  const translatedErrors: string[] = [];
  Object.keys(errorObject).forEach((key) => {
    if (errorObject[key].length > 0) {
      errorObject[key].forEach((item: string) => {
        translatedErrors.push(` ${item}`);
      });
    }
  });

  return translatedErrors;
};

export default translateErrors;
