import { TransportMode, TransportModeMap } from "@api/utils/normalizeCustomsClearance";
import { Currency, CurrencyMap } from "@api/utils/normalizeWarehouse";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import PopupBackground from "@component/PopupBackground";
import useDebounce from "@hooks/useDebounce";
import {
  fetchCreateCustomsClearances,
  fetchUpdateCustomsClearance,
  fetchVendorList,
  loadMoreVendorList,
} from "@redux/customsClearanceSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Col, Form, Input, Row, Select } from "antd";
import "moment/locale/zh-tw";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled(Form)`
  width: 830px;
  height: 400px;
  padding: 30px 28px 20px 32px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colorNeutral100};
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
`;

const CustomButton = styled(Button)`
  margin-right: 14px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 28px;
  bottom: 0;
`;

type Props = {
  customsClearancesId?: number;
  close: () => void;
};

const customsClearanceSlice = (state: RootState) => state.customsClearanceSlice;

export default function CustomsClearanceForm(props: Props) {
  const { customsClearancesId, close } = props;

  const dispatch = useDispatch();
  const { customsClearanceInfo, vendorListResult } = useSelector(customsClearanceSlice);

  const [form] = Form.useForm();

  useEffect(() => {
    let nameq = "";
    if (customsClearanceInfo) {
      nameq = customsClearanceInfo.vendorName;
    }
    dispatch(fetchVendorList(nameq));
  }, [dispatch, customsClearanceInfo]);

  const onChangeVendor = (value: Currency) => {
    const values = form.getFieldsValue();
    const { results } = vendorListResult;
    const vendor = results.find((item) => item.id === value);

    if (vendor) {
      form.setFieldsValue({
        ...values,
        currency: vendor.currency,
      });
    }
  };

  const handleOnVendorLoadMore = useCallback(() => {
    dispatch(loadMoreVendorList());
  }, [dispatch]);

  const vendorOptions = useMemo(() => {
    const { next, results } = vendorListResult;
    const options = results.map((vdr) => (
      <Select.Option key={vdr.id} value={vdr.id}>
        {vdr.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnVendorLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [vendorListResult, handleOnVendorLoadMore]);

  const handleOnVendorSearch = useDebounce((value: string) => {
    dispatch(fetchVendorList(value));
  }, 300);

  const onSubmit = (values: any) => {
    const payload = {
      currency: values.currency,
      currencyRate: parseFloat(values.currencyRate),
      processingFee: parseInt(values.processingFee, 10),
      shippingFee: parseInt(values.shippingFee, 10),
      tariff: parseFloat(values.tariff),
      tariffNumber: values.tariffNumber,
      transportMode: values.transportMode,
      vendor: values.vendor,
    };

    if (customsClearancesId) {
      dispatch(fetchUpdateCustomsClearance({ ...payload, customsClearancesId }));
    } else {
      dispatch(fetchCreateCustomsClearances(payload));
    }

    close();
  };

  return (
    <PopupBackground close={close} fixed>
      <Wrapper
        colon={false}
        labelCol={{ span: 9 }}
        labelAlign="left"
        form={form}
        initialValues={{
          tariffNumber: customsClearanceInfo?.tariffNumber,
          tariff: customsClearanceInfo?.tariff,
          vendor: customsClearanceInfo?.vendor,
          processingFee: customsClearanceInfo?.processingFee,
          currency: customsClearanceInfo?.currency,
          shippingFee: customsClearanceInfo?.shippingFee,
          currencyRate: customsClearanceInfo?.currencyRate,
          transportMode: customsClearanceInfo?.transportMode,
        }}
        onFinish={onSubmit}
      >
        <Row gutter={57}>
          <Col span={12}>
            <Form.Item label="進口報關單號" name="tariffNumber" rules={[{ required: true, message: "必填" }]}>
              <Input disabled={!!customsClearancesId} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="關稅" name="tariff" rules={[{ required: true, message: "必填" }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={57}>
          <Col span={12}>
            <Form.Item label="廠商名稱" name="vendor" rules={[{ required: true, message: "必填" }]}>
              <Select
                showSearch
                filterOption={false}
                onChange={onChangeVendor}
                onSearch={handleOnVendorSearch}
                disabled={!!customsClearancesId}
              >
                {vendorOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="處理費" name="processingFee" rules={[{ required: true, message: "必填" }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={57}>
          <Col span={12}>
            <Form.Item label="採購幣別" name="currency" rules={[{ required: true, message: "必填" }]}>
              <Select defaultValue={-1}>
                <Select.Option value={-1}>請選擇</Select.Option>
                {Object.keys(CurrencyMap).map((key: any) => (
                  <Select.Option value={parseInt(key, 10)}>{CurrencyMap[key as Currency]}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="運費" name="shippingFee" rules={[{ required: true, message: "必填" }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={57}>
          <Col span={12}>
            <Form.Item label="當下匯率" name="currencyRate" rules={[{ required: true, message: "必填" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="運送方式" name="transportMode" rules={[{ required: true, message: "必填" }]}>
              <Select defaultValue={-1}>
                <Select.Option value={-1}>請選擇</Select.Option>
                {Object.keys(TransportModeMap).map((key: any) => (
                  <Select.Option value={parseInt(key, 10)}>{TransportModeMap[key as TransportMode]}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <ButtonWrapper>
          <Form.Item>
            <CustomButton onClick={close}>取消</CustomButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Form.Item>
        </ButtonWrapper>
      </Wrapper>
    </PopupBackground>
  );
}
