/* eslint-disable no-redeclare */
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";

export enum StaffPermission {
  CAN_ACCESS_FINANCE_PAGE = "can_access_finance_page",
  CAN_ACCESS_ORDER_PAGE = "can_access_order_page",
  CAN_PURGE_SALES_RELATED_CACHE = "can_purge_sales_related_cache",
  CAN_MODIFY_MEMBERSHIP_UNLIMITED = "can_modify_membership_unlimited",
  CAN_COMMISSION_RATE_DISCOUNT = "can_commission_rate_discount",
}

// Function Overloads 根據呼叫時傳入的參數類型或數量來決定使用哪個函數定義
export function usePermission(permissionKey: StaffPermission[]): boolean[];
export function usePermission(permissionKey: StaffPermission): boolean;

export function usePermission(permissionKey: StaffPermission | StaffPermission[]): boolean | boolean[] {
  const memberAccessToken = useSelector((state: RootState) => state.auth.memberAccessToken);
  const accessTokens = memberAccessToken || [];
  if (Array.isArray(permissionKey)) {
    return permissionKey.map((key) => accessTokens.includes(key));
  }

  return accessTokens.includes(permissionKey);
}
