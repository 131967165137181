/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import { Form, Input, Typography, DatePicker, Button, InputNumber } from "antd";
import moment from "moment";
import UploadImageButton from "@component/UploadImageButton/UploadImgDirectly";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import { fetchEnvironmentList, fetchUpdateEnvironmentList } from "@redux/configSettingSlice";
import { EnvironmentResult } from "@api/configSettingApi";

const ImgContainer = styled.div`
  display: flex;
`;
const ImgInfo = styled.span`
  padding-left: 10px;
  color: #a7a7a7;
  align-self: center;
`;

const { Title } = Typography;
const Wrapper = styled.div`
  padding-left: 16px;
`;
const Row = styled.div`
  display: flex;
`;
const RowTitle = styled.div`
  align-self: flex-start;
  text-align: left;
  width: 115px;
  line-height: 32px;
  margin-right: 40px;
  font-size: 14px;
  white-space: nowrap;
`;
const RowContent = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const CustomRangePicker = styled(DatePicker.RangePicker)<{ showTime: any }>`
  width: 100%;
`;
const RequiredIcon = styled.span`
  color: red;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px;
`;
const CancelButton = styled(Button)`
  margin-right: 8px;
`;
const ConfigSetting = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const environmentResult = useSelector((state: RootState) => state.configSetting.environmentResult);
  const updateForm = (field: string, value: any) => {
    form.setFieldsValue({ [field]: value });
  };
  useEffect(() => {
    dispatch(fetchEnvironmentList());
  }, [dispatch]);

  const handleOnSubmit = () => {
    const formValues = form.getFieldsValue();
    const params: EnvironmentResult = {
      defaultRecommendPageIds: JSON.parse(`[${formValues.defaultRecommendPageIds}]`),
      vipPromotionStartDate: formValues.timePeriod[0].format("YYYY-MM-DD HH:mm:ss"),
      vipPromotionEndDate: formValues.timePeriod[1].format("YYYY-MM-DD HH:mm:ss"),
      vipPromotionContent: formValues.vipPromotionContent,
      defaultAdditionPurchaseApplyMaxQty: formValues.defaultAdditionPurchaseApplyMaxQty,
      promotionMaxNumberOfBindRules: formValues.promotionMaxNumberOfBindRules,
      vipPromotionImage: formValues.vipPromotionImage,
      cvsLimitLongestEdge: formValues.cvsLimitLongestEdge,
      cvsLimitSecondLongestEdge: formValues.cvsLimitSecondLongestEdge,
      cvsLimitShortestEdge: formValues.cvsLimitShortestEdge,
      cvsLimitVolume: formValues.cvsLimitVolume,
      cvsLimitWeight: formValues.cvsLimitWeight,
      cvsLimitPrice: formValues.cvsLimitPrice,
      newCustomerPromotionRuleIds: JSON.parse(`[${formValues.newCustomerPromotionRuleIds}]`),
      vipCustomerRenewPromotionRuleIds: JSON.parse(`[${formValues.vipCustomerRenewPromotionRuleIds}]`),
      searchKeywords: Array.isArray(formValues.searchKeywords)
        ? formValues.searchKeywords
        : formValues.searchKeywords.split(","),
      deployCvsSuspendStartDate: formValues.deployCvsSuspendDate[0].format("YYYY-MM-DD HH:mm:ss"),
      deployCvsSuspendEndDate: formValues.deployCvsSuspendDate[1].format("YYYY-MM-DD HH:mm:ss"),
    };
    dispatch(fetchUpdateEnvironmentList(params));
  };

  const handleDefault = useCallback(() => {
    const formatedEnvironmentResults = environmentResult && {
      ...environmentResult,
      timePeriod: [moment(environmentResult.vipPromotionStartDate), moment(environmentResult.vipPromotionEndDate)],
      deployCvsSuspendDate: [
        moment(environmentResult.deployCvsSuspendStartDate),
        moment(environmentResult.deployCvsSuspendEndDate),
      ],
    };
    form.setFieldsValue(formatedEnvironmentResults);
  }, [environmentResult, form]);

  useEffect(() => {
    handleDefault();
  }, [handleDefault]);

  return (
    <Wrapper>
      <Form form={form} onFinish={handleOnSubmit}>
        <Title level={5}>行銷推廣設定</Title>
        <Row>
          <RowTitle>
            加價購限購量
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item
              name="defaultAdditionPurchaseApplyMaxQty"
              rules={[{ required: true, message: "數值不得為負值" }]}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>新客推廣優惠券</RowTitle>
          <RowContent width={500}>
            <Form.Item name="newCustomerPromotionRuleIds">
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>續約禮優惠券</RowTitle>
          <RowContent width={500}>
            <Form.Item name="vipCustomerRenewPromotionRuleIds">
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>銷售頁推廣商品清單</RowTitle>
          <RowContent width={500}>
            <Form.Item name="defaultRecommendPageIds">
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            活動頁優惠券顯示數量
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="promotionMaxNumberOfBindRules" rules={[{ required: true, message: "數值不得為負值" }]}>
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Title level={5}>搜尋建議</Title>
        <Row>
          <RowTitle>關鍵字設定</RowTitle>
          <RowContent width={500}>
            <Form.Item name="searchKeywords">
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Title level={5}>黑卡全新客9折推廣</Title>
        <Row>
          <RowTitle>時間區間</RowTitle>
          <RowContent width={500}>
            <Form.Item name="timePeriod">
              <CustomRangePicker
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")],
                }}
              />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>推廣文字</RowTitle>
          <RowContent width={500}>
            <Form.Item name="vipPromotionContent">
              <Input placeholder="請輸入推廣文案最多12個字" maxLength={12} />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>推廣圖片</RowTitle>
          <RowContent width={500}>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <Form.Item name="vipPromotionImage">
                  <ImgContainer>
                    <UploadImageButton
                      imageUrl={getFieldValue("vipPromotionImage") || ""}
                      finishUpload={(url) => {
                        updateForm("vipPromotionImage", url);
                      }}
                    />
                    <ImgInfo>尺寸=900x1200(單位px), 大小上限為2MB</ImgInfo>
                  </ImgContainer>
                </Form.Item>
              )}
            </Form.Item>
          </RowContent>
        </Row>
        <Title level={5}>超商門檻</Title>
        <Row>
          <RowTitle>
            最長邊(cm)
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="cvsLimitLongestEdge" rules={[{ required: true, message: "數值不得為負值" }]}>
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            次長邊(cm)
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="cvsLimitSecondLongestEdge" rules={[{ required: true, message: "數值不得為負值" }]}>
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            最短邊(cm)
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="cvsLimitShortestEdge" rules={[{ required: true, message: "數值不得為負值" }]}>
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            體積(cm^3)
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="cvsLimitVolume" rules={[{ required: true, message: "數值不得為負值" }]}>
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            重量(g)
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="cvsLimitWeight" rules={[{ required: true, message: "數值不得為負值" }]}>
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            金額
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="cvsLimitPrice" rules={[{ required: true, message: "數值不得為負值" }]}>
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </RowContent>
        </Row>
        <Title level={5}>倉庫拋單控制</Title>
        <Row>
          <RowTitle>
            超商停止要號時間區間<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="deployCvsSuspendDate" rules={[{ required: true, message: "欄位不得為空" }]}>
              <CustomRangePicker
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{
                  hideDisabledOptions: true,
                }}
              />
            </Form.Item>
          </RowContent>
        </Row>
        <ButtonContainer>
          <CancelButton onClick={handleDefault}>取消</CancelButton>
          <Button type="primary" htmlType="submit">
            儲存此頁面
          </Button>
        </ButtonContainer>
      </Form>
    </Wrapper>
  );
};

export default ConfigSetting;
