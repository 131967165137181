import { PlusOutlined } from "@ant-design/icons";
import throughShipmentApi, {
  ContractType,
  convertRegionType,
  Currency,
  RegionType,
  RetailList,
} from "@api/throughShipment/throughShipmentApi";
import PageTitle from "@component/PageTitle";
import useDebounce from "@hooks/useDebounce";
import {
  dealerState,
  fetchAddRetailProductLine,
  fetchCreateDealer,
  fetchDealerInfo,
  fetchRetailList,
  fetchRetailProductLine,
  fetchRetailRegion,
  fetchUpdateDealer,
} from "@redux/dealerSlice";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import { Button, Divider, Form, Input, InputNumber, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  padding-left: 16px;
`;
const Row = styled.div<{ marginTop?: string }>`
  display: flex;
  align-items: center;
  margin-top: ${({ marginTop }) => marginTop};
`;
const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
`;
const RowTitle = styled.div<{ bold?: boolean; paddingLeft?: string }>`
  align-self: flex-start;
  width: 140px;
  line-height: 32px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  padding-left: ${({ paddingLeft }) => paddingLeft || "24px"};
`;
const RowContent = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;
const LeftSideText = styled.div`
  min-width: 50px;
  margin-right: 23px;
`;
const RightSideText = styled.div`
  min-width: 50px;
  margin-left: 23px;
`;
const DateText = styled.div`
  min-width: 100px;
  font-size: 14px;
  font-weight: bold;
`;
const CustomInput = styled(Input)`
  flex: 1;
  margin-right: 10px;
`;
const SearchList = styled.div`
  min-height: 40px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  background: #ffffff;
  position: absolute;
  top: 101%;
  width: 100%;
  padding: 4px 0px;
  z-index: 10;
`;
const SearchWrapper = styled.div`
  position: relative;
`;
const SearchItem = styled.div`
  padding: 5px 12px;
  font-size: 14px;
  cursor: pointer;

  &:active {
    background: #e6f7ff;
  }

  &:hover {
    background: #f2f4f5;
  }
`;
const RequiredIcon = styled.span`
  color: red;
`;

interface FormState {
  regionType: RegionType;
  region: number;
  name: string;
  productLine: number;
  currency: Currency;
  contractType: ContractType;
  memo: string;
  daysAfterMonthlyStatement: number;
  address: string;
  phone: string;
  foreignRegion: string; // 給境外通路用，因為和原本的 region 要共用邏輯會很複雜而且出錯率高所以抽出
}

const DealerContent = () => {
  const dispatch = useDispatch();
  const { dealerInfo, retailRegion, retailProductLine, retailList, createDealerId } = useSelector(dealerState);

  const { dealerId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [form] = Form.useForm<FormState>();

  const [searchRetailText, setSearchRetailText] = useState<string>("");
  const [searchRegionText, setSearchRegionText] = useState<string>("");
  const [newProductLineText, setNewProductLineText] = useState<string>("");
  const [focusRetailInput, setFocusRetailInput] = useState<boolean>(false);
  const [focusRegionInput, setFocusRegionInput] = useState<boolean>(false);

  const searchRegionType = searchParams.get("regionType") as string;

  const onAddProductLine = () => {
    if (newProductLineText) {
      dispatch(fetchAddRetailProductLine({ name: newProductLineText }));
      setNewProductLineText("");
    }
  };

  const onSearchProductLine = useDebounce((text: string) => {
    dispatch(fetchRetailProductLine({ nameQ: text }));
  }, 1000);

  const onAutoSettingPreviousRetail = (retail: RetailList) => {
    form.setFieldsValue({
      address: retail.address,
      phone: retail.phone,
      name: retail.name,
    });
  };

  const onSubmit = async (values: FormState) => {
    if (dealerId) {
      const payload = {
        address: values.address,
        phone: values.phone,
        currency: values.currency,
        contractType: values.contractType,
        memo: values.memo,
        daysAfterMonthlyStatement: Number(values.daysAfterMonthlyStatement),
      };
      dispatch(fetchUpdateDealer({ ...payload, dealerId: parseInt(dealerId, 10) }));
    } else {
      let resultRegion = values.region;
      let isError = false;
      if (searchRegionType && parseInt(searchRegionType, 10) === RegionType.FOREIGN) {
        const foreign = retailRegion.results.find((item) => item.name === values.foreignRegion);
        if (foreign) {
          resultRegion = foreign.id;
        } else {
          try {
            const response = await throughShipmentApi.fetchCreateRegion({
              name: values.foreignRegion,
              regionType: RegionType.FOREIGN,
            });
            resultRegion = response.id;
          } catch (error: any) {
            isError = true;
          }
        }
      }

      if (isError) {
        return;
      }

      const payload = {
        name: values.name,
        address: values.address,
        phone: values.phone,
        region: resultRegion,
        productLine: values.productLine,
        currency: values.currency,
        contractType: values.contractType,
        memo: values.memo,
        daysAfterMonthlyStatement: Number(values.daysAfterMonthlyStatement),
      };
      dispatch(fetchCreateDealer(payload));
    }
  };

  const regionComponent = () => {
    if (dealerInfo) {
      return <Form.Item name="region">{dealerInfo.retailer.region.name}</Form.Item>;
    }
    if (searchRegionType && parseInt(searchRegionType, 10) === RegionType.FOREIGN) {
      return (
        <SearchWrapper>
          <Form.Item name="foreignRegion" rules={[{ required: true, message: "必填" }]}>
            <Input
              autoComplete="off"
              onFocus={() => setFocusRegionInput(true)}
              onBlur={() => {
                setTimeout(() => {
                  setFocusRegionInput(false);
                }, 200);
              }}
              onChange={(e) => setSearchRegionText(e.target.value)}
            />
          </Form.Item>
          {focusRegionInput && (
            <SearchList>
              {retailRegion.results
                .filter((item) => item.name.includes(searchRegionText))
                .map((item) => (
                  <SearchItem
                    role="presentation"
                    key={item.id}
                    onClick={() => {
                      form.setFieldsValue({ name: "", foreignRegion: item.name });
                      dispatch(fetchRetailList({ region: item.id }));
                      setFocusRegionInput(false);
                    }}
                  >
                    {item.name}
                  </SearchItem>
                ))}
            </SearchList>
          )}
        </SearchWrapper>
      );
    }
    return (
      <Form.Item name="region" rules={[{ required: true, message: "必填" }]}>
        <Select
          onChange={(id) => {
            form.setFieldsValue({ name: "" });
            dispatch(fetchRetailList({ region: id as number }));
          }}
        >
          {retailRegion.results.map((item) => (
            <Select.Option value={item.id}>{item.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  useEffect(() => {
    if (dealerId) {
      dispatch(fetchDealerInfo(parseInt(dealerId, 10)));
    }
  }, [dispatch, dealerId]);

  useEffect(() => {
    if (!dealerInfo && searchRegionType) {
      dispatch(
        fetchRetailRegion({
          regionType: parseInt(searchRegionType, 10) as RegionType,
        }),
      );
      dispatch(fetchRetailProductLine());
    }
  }, [dispatch, searchParams, dealerInfo, searchRegionType]);

  useEffect(() => {
    if (dealerInfo) {
      form.setFieldsValue({
        name: dealerInfo.retailer.name,
        address: dealerInfo.retailer.address,
        phone: dealerInfo.retailer.phone,
        region: dealerInfo.retailer.region.id,
        productLine: dealerInfo.productLine.id,
        currency: dealerInfo.currency,
        contractType: dealerInfo.contractType,
        memo: dealerInfo.memo,
        daysAfterMonthlyStatement: dealerInfo.daysAfterMonthlyStatement,
      });
    }
  }, [form, dealerInfo]);

  useEffect(() => {
    if (createDealerId) {
      navigate(`/dealer/${createDealerId}`);
    }
  }, [createDealerId, navigate]);

  return (
    <Wrapper>
      <PageTitle title="通路/經銷商 - 通路資訊" />
      <Form form={form} initialValues={{}} onFinish={onSubmit} onFinishFailed={showRequiredFieldsWarning}>
        <Row>
          <RowTitle bold paddingLeft="ppx">
            通路/經銷商類型
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="regionType">
              <Title>
                {searchParams.get("regionType")
                  ? convertRegionType(parseInt(searchParams.get("regionType") as string, 10) as RegionType)
                  : convertRegionType(dealerInfo?.retailer.region.regionType || -1)}
              </Title>
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            所在地區<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>{regionComponent()}</RowContent>
        </Row>
        <Row>
          <RowTitle>
            通路名稱<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            {dealerInfo ? (
              <Form.Item name="name">
                <div>{dealerInfo.retailer.name}</div>
              </Form.Item>
            ) : (
              <SearchWrapper>
                <Form.Item name="name" rules={[{ required: true, message: "必填" }]}>
                  <Input
                    autoComplete="off"
                    onFocus={() => setFocusRetailInput(true)}
                    onBlur={() => {
                      setTimeout(() => {
                        setFocusRetailInput(false);
                      }, 200);
                    }}
                    onChange={(e) => setSearchRetailText(e.target.value)}
                  />
                </Form.Item>
                {focusRetailInput && (
                  <SearchList>
                    {retailList.results
                      .filter((item) => item.name.includes(searchRetailText))
                      .map((item) => (
                        <SearchItem
                          role="presentation"
                          key={item.id}
                          onClick={() => {
                            onAutoSettingPreviousRetail(item);
                            setFocusRetailInput(false);
                          }}
                        >
                          {item.name}
                        </SearchItem>
                      ))}
                  </SearchList>
                )}
              </SearchWrapper>
            )}
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            通路線別<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            {dealerInfo ? (
              <Form.Item name="productLine">
                <div>{dealerInfo.productLine.name}</div>
              </Form.Item>
            ) : (
              <Form.Item name="productLine" rules={[{ required: true, message: "必填" }]}>
                <Select
                  showSearch
                  filterOption={false}
                  onSearch={onSearchProductLine}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Row style={{ padding: "0 8px 4px" }}>
                        <CustomInput
                          value={newProductLineText}
                          onChange={(e) => setNewProductLineText(e.target.value)}
                        />
                        <Typography.Link onClick={onAddProductLine}>
                          <PlusOutlined /> 新增項目
                        </Typography.Link>
                      </Row>
                    </>
                  )}
                >
                  {retailProductLine.results.map((item) => (
                    <Select.Option value={item.id}>{item.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            通路地址<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="address" rules={[{ required: true, message: "必填" }]}>
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            通路電話<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="phone" rules={[{ required: true, message: "必填" }]}>
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle bold paddingLeft="0px">
            合作條件
          </RowTitle>
        </Row>
        <Row marginTop="20px">
          <RowTitle>
            合作模式<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="contractType" rules={[{ required: true, message: "必填" }]}>
              <Select defaultValue={-1}>
                <Select.Option value={-1} disabled>
                  請選擇
                </Select.Option>
                <Select.Option value={ContractType.BUYOUT_REFUNDABLE}>買斷可退</Select.Option>
                <Select.Option value={ContractType.BUY_AND_NOT_RETURN}>買斷不可退</Select.Option>
                <Select.Option value={ContractType.CONSIGNMENT}>寄倉</Select.Option>
                <Select.Option value={ContractType.TRANSFER}>轉單</Select.Option>
              </Select>
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            付款條件<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="memo" rules={[{ required: true, message: "必填" }]}>
              <Input.TextArea />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            經銷商付款時間<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <Row>
            <LeftSideText>出貨後</LeftSideText>
            <Form.Item name="daysAfterMonthlyStatement" noStyle rules={[{ required: true, message: "必填" }]}>
              <InputNumber type="number" />
            </Form.Item>
            <RightSideText>日</RightSideText>
          </Row>
        </Row>
        <Row marginTop="28px">
          <RowTitle>付款予供應商時間</RowTitle>
          <Row>
            <LeftSideText>出貨後</LeftSideText>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <DateText>
                  {getFieldValue("daysAfterMonthlyStatement")
                    ? Number(getFieldValue("daysAfterMonthlyStatement")) + 30
                    : "N/A"}
                </DateText>
              )}
            </Form.Item>
            <RightSideText>日付款給供應商</RightSideText>
          </Row>
        </Row>
        <Row marginTop="20px">
          <RowTitle>
            交易幣別<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="currency" rules={[{ required: true, message: "必填" }]}>
              <Select defaultValue={-1}>
                <Select.Option value={-1} disabled>
                  請選擇
                </Select.Option>
                <Select.Option value={Currency.TWD}>TWD</Select.Option>
                <Select.Option value={Currency.USD}>USD</Select.Option>
                <Select.Option value={Currency.RMB}>RMD</Select.Option>
              </Select>
            </Form.Item>
          </RowContent>
        </Row>
        <Footer>
          <Button type="primary" htmlType="submit">
            {dealerInfo ? "儲存此分頁" : "建立資料"}
          </Button>
        </Footer>
      </Form>
    </Wrapper>
  );
};

export default DealerContent;
