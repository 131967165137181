import { CarrierParams, UpdateShipmentDetail } from "@api/sharing/specialShipmentApi";
import PopupBackground from "@component/PopupBackground";
import useCityRegionCode from "@hooks/useCityRegionCode";
import {
  createSpecialShipment,
  fetchCarrierList,
  setOpenShipmentFormType,
  specialShipmentState,
  updateCarrierList,
  updateCreateSpecialShipmentId,
  updateSpecialShipment,
} from "@redux/specialShipmentSlice";
import { Button, Col, DatePicker, Form, Input, Radio, Row, Select, Space, Spin } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  SpecialShipmentPurpose,
  specialShipmentPurposeOptions,
  warehouseCodeOptions,
} from "./specialShipmentConstants";

const PopupContent = styled.div`
  max-width: 70vw;
  padding: 20px;
  background: ${({ theme }) => theme.colorNeutral100};
  max-height: 90vh;
  overflow: auto;
  width: 500px;
  && .ant-form-item-required.ant-form-item-no-colon::before {
    display: none;
  }
  && .ant-form-item-required.ant-form-item-no-colon::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
`;
const NoticeText = styled.div`
  color: ${({ theme }) => theme.colorSecondary500};
`;

type Props = {
  type?: "create" | "update";
  onClose: () => void;
};
const SpecialShipmentFormModal = (props: Props) => {
  const { type, onClose } = props;
  const dispatch = useDispatch();
  const { isFetching, carrierList, specialShipmentDetail, createSpecialShipmentId } = useSelector(specialShipmentState);
  const [form] = Form.useForm();
  const { cityNameOptions, getCityRegionOptions } = useCityRegionCode();
  const [updateInit, setUpdateInit] = useState(false);
  const warehouseCodeFormValue = Form.useWatch("warehouseCode", form);
  const purposeFormValue = Form.useWatch("purpose", form);

  const disabledDate = (inputDate: any) => {
    return inputDate.isBefore(moment().subtract(1, "days")) || inputDate > moment().add(0.5, "y");
  };

  const handleGetCarrierChange = useCallback(
    (params?: CarrierParams) => {
      form.setFieldsValue({ carrierId: undefined });
      if (params) {
        dispatch(fetchCarrierList(params));
        return;
      }
      const warehouseCode = form.getFieldValue("warehouseCode");
      const purpose = form.getFieldValue("purpose");
      if (warehouseCode && purpose) {
        dispatch(fetchCarrierList({ warehouseCode, purpose }));
        form.setFieldsValue({ carrierId: undefined });
      }
    },
    [dispatch, form],
  );
  const handleOnSubmit = () => {
    const formData = form.getFieldsValue();
    const { estArrivedDate, estDeployedDate, region, city, address, ...restData } = formData;
    const payload: UpdateShipmentDetail = {
      ...restData,
      receiverZipcode: region.value,
      receiverAddress: `${city}${region.label}${address}`,
      estDeployedDate: estDeployedDate && estDeployedDate.format("YYYY-MM-DD"),
      estArrivedDate: estArrivedDate && estArrivedDate.format("YYYY-MM-DD"),
    };
    // 建立
    if (type === "create") {
      dispatch(createSpecialShipment(payload));
    }
    // 更新
    if (type === "update" && specialShipmentDetail) {
      dispatch(updateSpecialShipment({ shipmentId: specialShipmentDetail.id, payload }));
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    if (createSpecialShipmentId) {
      window.open(`/special-shipment/edit/${createSpecialShipmentId}`, "_blank");
      dispatch(updateCreateSpecialShipmentId(undefined));
      dispatch(setOpenShipmentFormType(undefined));
    }
  }, [createSpecialShipmentId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(updateCarrierList(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    if (type === "create") return;
    if (!updateInit && specialShipmentDetail) {
      const { receiverZipcode, receiverAddress } = specialShipmentDetail;
      if (carrierList?.length) {
        form.setFieldsValue({
          receiverZipcode,
          warehouseCode: specialShipmentDetail.warehouseCode,
          purpose: specialShipmentDetail.purpose,
          condition: specialShipmentDetail.condition,
          desc: specialShipmentDetail.desc,
          receiverName: specialShipmentDetail.receiverName,
          receiverPhone: specialShipmentDetail.receiverPhone,
          address: receiverAddress.slice(6),
          city: receiverAddress.slice(0, 3),
          region: {
            label: receiverAddress.slice(3, 6),
            value: receiverZipcode,
          },
          carrierId: specialShipmentDetail.carrierId,
          estDeployedDate: specialShipmentDetail.estDeployedDate
            ? moment(specialShipmentDetail.estDeployedDate)
            : undefined,
          estArrivedDate: specialShipmentDetail.estArrivedDate
            ? moment(specialShipmentDetail.estArrivedDate)
            : undefined,
        });
        setUpdateInit(true);
      } else {
        handleGetCarrierChange({
          warehouseCode: specialShipmentDetail.warehouseCode,
          purpose: specialShipmentDetail.purpose,
        });
      }
    }
  }, [carrierList?.length, form, handleGetCarrierChange, specialShipmentDetail, type, updateInit]);

  return (
    <PopupBackground close={() => {}}>
      <PopupContent>
        <Form form={form} colon={false} labelCol={{ span: 6 }} labelAlign="left" onFinish={handleOnSubmit}>
          <Spin spinning={isFetching}>
            <Form.Item name="warehouseCode" label="出庫倉庫" rules={[{ required: true, message: "" }]}>
              <Select options={warehouseCodeOptions} onChange={() => handleGetCarrierChange()} placeholder="請選擇" />
            </Form.Item>
            <Form.Item name="estDeployedDate" label="預期拋單日期" rules={[{ required: true, message: "" }]}>
              <DatePicker placeholder="請選擇日期" disabledDate={disabledDate} />
            </Form.Item>
            <Form.Item
              name="estArrivedDate"
              label="預計到貨日期"
              extra={<NoticeText>*此資訊用於顯示倉庫備註中，僅供倉庫人員參考</NoticeText>}
            >
              <DatePicker placeholder="請選擇日期" disabledDate={disabledDate} />
            </Form.Item>
            <Form.Item
              label="訂購緣由"
              name="purpose"
              rules={[{ required: true, message: "請填入訂購緣由" }]}
              extra={
                <>
                  <NoticeText>
                    *行銷推廣：行銷推廣 / 公關品 / 抽獎贈品 / 提品 / 試吃品請選擇此項目，並於說明補充用途
                  </NoticeText>
                  <NoticeText>*出貨：僅限客服補貨使用 </NoticeText>
                  <NoticeText>*退倉：將商品退還給廠商</NoticeText>
                </>
              }
            >
              <Select
                placeholder="請選擇"
                options={specialShipmentPurposeOptions}
                onChange={() => handleGetCarrierChange()}
              />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.purpose !== curValues.purpose}>
              {({ getFieldValue }) => {
                const purpose = getFieldValue("purpose");
                form.setFieldValue("condition", "GOOD");
                return purpose === SpecialShipmentPurpose.WITHDRAW ? (
                  <Form.Item wrapperCol={{ span: 8 }} label=" " name="condition">
                    <Radio.Group onChange={() => {}}>
                      <Radio value="GOOD" defaultChecked>
                        良品
                      </Radio>
                      <Radio value="BAD">壞品</Radio>
                    </Radio.Group>
                  </Form.Item>
                ) : null;
              }}
            </Form.Item>
            <Form.Item
              label="緣由說明"
              name="desc"
              rules={[
                {
                  required: true,
                  message: "說明最少5字，最多30字",
                  max: 30,
                  min: 5,
                },
              ]}
            >
              <Input placeholder="最少5字，最多30字" />
            </Form.Item>
            <Form.Item label="運送方式" name="carrierId" rules={[{ required: true, message: "" }]}>
              <Select
                placeholder={warehouseCodeFormValue && purposeFormValue ? "請選擇" : "請先選擇出庫倉庫和訂購緣由"}
                options={carrierList?.map((item) => ({ label: item.name, value: item.carrierId }))}
                disabled={!warehouseCodeFormValue || !purposeFormValue}
              />
            </Form.Item>
            <Form.Item name="receiverName" label="收件人" rules={[{ required: true, message: "" }]}>
              <Input type="text" />
            </Form.Item>
            <Form.Item label="收件電話" name="receiverPhone" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="收件地址" required>
              <Row gutter={[4, 0]}>
                <Col span={12}>
                  <Form.Item name="city" rules={[{ required: true, message: "" }]}>
                    <Select options={cityNameOptions} placeholder="請選擇縣市" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.city !== curValues.city} noStyle>
                    {({ getFieldValue }) => {
                      const city = getFieldValue("city");
                      return (
                        <Form.Item name="region" rules={[{ required: true, message: "" }]}>
                          <Select
                            labelInValue
                            options={city ? getCityRegionOptions(city) : []}
                            disabled={form.getFieldValue("city") === undefined}
                            placeholder="請選擇區域"
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[4, 0]}>
                <Col flex="auto">
                  <Form.Item name="address" rules={[{ required: true, message: "" }]}>
                    <Input type="text" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Row justify="end">
              <Space>
                <Button type="default" onClick={() => handleOnClose()}>
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  確認
                </Button>
              </Space>
            </Row>
          </Spin>
        </Form>
      </PopupContent>
    </PopupBackground>
  );
};

export default SpecialShipmentFormModal;
