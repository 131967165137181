import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { fetchDeleteProduct } from "@redux/returnOrderSlice";
import { Button, Modal, Table } from "antd";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { ReturnProduct, ReturnProductErrorStatus } from "../interfaces";

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const EditText = styled(Button)`
  padding: 0;
`;
const DeleteText = styled(Button)`
  margin-left: 23px;
  padding: 0;
`;

type Props = {
  list: ReturnProduct[];
  openProductForm: (product: ReturnProduct) => void;
  pagination: {
    limit: number;
    current: number;
  };
  onChange: (limit: any) => void;
  canEdit?: boolean;
  sourceWarehouseCode: string;
};

export default function ReturnProductList(props: Props) {
  const { list, openProductForm, pagination, onChange, canEdit, sourceWarehouseCode } = props;
  const dispatch = useDispatch();
  const { returnOrderId } = useParams();
  const isTCAT = sourceWarehouseCode === "TCAT";

  const columns: {
    title: string;
    dataIndex: keyof ReturnProduct;
    render?: any;
    width?: string | number;
  }[] = [
    { title: "ID", dataIndex: "id" },
    {
      title: "SKU(品號)",
      dataIndex: "sku",
      render: (sku: string, record: ReturnProduct) => {
        const { errReason } = record;
        return errReason && errReason.find((err) => err === ReturnProductErrorStatus.DUPLICATE) ? (
          <span style={{ color: "red" }}>{sku}</span>
        ) : (
          sku
        );
      },
    },
    { title: "商品名稱", dataIndex: "productName" },
    {
      title: isTCAT ? "成本" : "倉別",
      dataIndex: isTCAT ? "cost" : "sourceStorageName",
    },
    {
      title: "商品效期",
      dataIndex: "effectiveDate",
      render: (effectiveDate: string, record: ReturnProduct) =>
        effectiveDate ? <span>{effectiveDate}</span> : <span>無效期限制</span>,
    },
    {
      title: "批號",
      dataIndex: "batch",
      render: (batch: string, record: ReturnProduct) => <span>{batch || "無批號"}</span>,
    },
    { title: "庫存量", dataIndex: "stock" },
    {
      title: "商品數量(件)",
      dataIndex: "qty",
    },
    {
      title: "",
      dataIndex: "orderId",
      width: 30,
      render: (_: any, record: ReturnProduct) => (
        <Flex>
          <EditText
            type="link"
            onClick={() => {
              openProductForm(record);
            }}
            disabled={!canEdit}
          >
            編輯
          </EditText>
          <DeleteText
            type="link"
            onClick={() => {
              Modal.confirm({
                title: "確定要刪除此商品嗎?",
                icon: <ExclamationCircleOutlined />,
                okText: "是",
                cancelText: "否",
                onOk() {
                  dispatch(fetchDeleteProduct({ returnOrderId, productId: record.id }));
                },
              });
            }}
            disabled={!canEdit}
          >
            刪除
          </DeleteText>
        </Flex>
      ),
    },
  ];

  return (
    <Table
      columns={columns.filter((item) => (isTCAT ? item.dataIndex !== "batch" : item))}
      scroll={{ x: "max-content" }}
      dataSource={list}
      pagination={{
        pageSize: pagination.limit,
        current: pagination.current,
        showSizeChanger: false,
        total: list.length,
        onChange,
      }}
    />
  );
}
