import PopupBackground from "@component/PopupBackground";
import { clearError, fetchCustomsClearancesPurchaseAdd } from "@redux/customsClearanceSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Form, Input } from "antd";
import "moment/locale/zh-tw";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const Wrapper = styled(Form)`
  width: 650px;
  height: 223px;
  padding: 40px 28px 20px 32px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colorNeutral100};
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
`;

const CustomButton = styled(Button)`
  margin-right: 14px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 28px;
  bottom: 0;
`;

const Error = styled.span`
  position: absolute;
  left: 32px;
  bottom: 22px;
  color: #ec6922;
  ${fontStyle("13px", "16px")};
`;

type Props = {
  close: () => void;
};

const customsClearanceSlice = (state: RootState) => state.customsClearanceSlice;

export default function CustomsClearanceAddForm(props: Props) {
  const { close } = props;

  const { customsClearanceId } = useParams();
  const integerCustomsClearanceId = parseInt(customsClearanceId, 10);

  const dispatch = useDispatch();
  const { isEditDone, addErrorMessage, purchaseLinesResult } = useSelector(customsClearanceSlice);

  const closePopup = useCallback(() => {
    dispatch(clearError());
    close();
  }, [close, dispatch]);

  const onSubmit = (values: any) => {
    const filterSpace = values.stoNumber.replace(/[\s]+/g, "");
    dispatch(
      fetchCustomsClearancesPurchaseAdd({
        customsClearancesId: integerCustomsClearanceId,
        stoNumbers: filterSpace.split(","),
        abnormalCost: purchaseLinesResult.results.abnormalCost,
      }),
    );
  };

  useEffect(() => {
    if (isEditDone && !addErrorMessage) {
      closePopup();
    }
  }, [isEditDone, addErrorMessage, closePopup]);

  return (
    <PopupBackground close={closePopup} fixed>
      <Wrapper colon={false} labelCol={{ span: 5 }} labelAlign="left" onFinish={onSubmit}>
        <Form.Item
          label="LMS編號"
          name="stoNumber"
          wrapperCol={{ span: 18 }}
          rules={[{ required: true, message: "必填" }]}
        >
          <Input placeholder="可輸入多筆LMS編號，以逗號區隔" />
        </Form.Item>
        <Error>{addErrorMessage}</Error>
        <ButtonWrapper>
          <Form.Item>
            <CustomButton onClick={closePopup}>取消</CustomButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Form.Item>
        </ButtonWrapper>
      </Wrapper>
    </PopupBackground>
  );
}
