import { resetListParams, specialShipmentState, updateListParams } from "@redux/specialShipmentSlice";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";

import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  approvalStatusOptions,
  orderStatusOptions,
  specialShipmentPurposeOptions,
  warehouseCodeOptions,
} from "./specialShipmentConstants";

const FilterWrapper = styled.div<{ display: boolean }>`
  padding: "43px 13px";
  display: ${({ display }) => (display ? "block" : "none")};
`;

const { RangePicker } = DatePicker;

type Props = {
  open: boolean;
};

export default function FilterForm(props: Props) {
  const { open } = props;
  const dispatch = useDispatch();
  const { specialShipmentListParams } = useSelector(specialShipmentState);

  const [form] = Form.useForm();

  const dateFormatter = (date: moment.Moment | null) => date?.format("YYYY-MM-DD");
  const clearFilter = () => {
    dispatch(resetListParams());
    form.resetFields();
  };

  const confirmFilter = (e: React.FormEvent<HTMLFormElement>) => {
    const { estDeployedDate, shippingDate, ...formDate } = form.getFieldsValue();
    dispatch(updateListParams({ ...specialShipmentListParams, ...formDate }));
    e.preventDefault();
  };

  return (
    <FilterWrapper display={open}>
      <Form onFinish={confirmFilter} form={form} labelCol={{ span: 7 }} labelAlign="left">
        <Row gutter={[12, 4]}>
          <Col span={9}>
            <Form.Item label="訂單編號" name="orderNumber">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item label="SKU(品號)" name="sku">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <Form.Item label="訂單狀態" name="status">
              <Select options={orderStatusOptions} placeholder="請選擇" allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 4]}>
          <Col span={9}>
            <Form.Item label="預期拋單日期" name="estDeployedDate">
              <Form.Item name="estDeployedDateAfter" noStyle />
              <Form.Item name="estDeployedDateBefore" noStyle />
              <RangePicker
                onChange={(date) => {
                  if (!date) return;
                  form.setFieldValue("estDeployedDateAfter", dateFormatter(date[0]));
                  form.setFieldValue("estDeployedDateBefore", dateFormatter(date[1]));
                }}
                placeholder={["開始日期", "結束日期"]}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item label="實際出貨日期" name="shippingDate">
              <Form.Item name="shippingDateAfter" noStyle />
              <Form.Item name="shippingDateBefore" noStyle />
              <RangePicker
                onChange={(date) => {
                  if (!date) return;
                  form.setFieldValue("shippingDateAfter", dateFormatter(date[0]));
                  form.setFieldValue("shippingDateBefore", dateFormatter(date[1]));
                }}
                placeholder={["開始日期", "結束日期"]}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <Form.Item label="審核狀態" name="approvalStatus">
              <Select options={approvalStatusOptions} placeholder="請選擇" allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 4]}>
          <Col span={7}>
            <Form.Item label="出庫倉庫" name="warehouseCode">
              <Select options={warehouseCodeOptions} placeholder="請選擇" allowClear />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="出貨緣由" name="purpose">
              <Select options={specialShipmentPurposeOptions} placeholder="請選擇" allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 4]} justify="end">
          <Col>
            <Button onClick={clearFilter}>清除篩選條件</Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Col>
        </Row>
      </Form>
    </FilterWrapper>
  );
}
