import React, { useState } from "react";
import { fetchDeleteReturnOrder, returnOrderState, setReturnOrderListFilter } from "@redux/returnOrderSlice";
import { Button, Modal, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { OrderStatus, OrderStatusLabel } from "@constant/OrderStatus";
import { ReviewStateByPositionLabel } from "@constant/ReviewState";
import { ReturnOrderDetail } from "./interfaces";

export default function ReturnOrderList() {
  const dispatch = useDispatch();
  const { returnOrderList, returnOrderListFilter, isFetching } = useSelector(returnOrderState);
  const [page, setPage] = useState<number>(1);

  const fetchPageChange = (value: number) => {
    setPage(value);
    dispatch(
      setReturnOrderListFilter({
        ...returnOrderListFilter,
        offset: (value - 1) * returnOrderListFilter.limit,
      }),
    );
  };

  const deleteOrder = (orderId: number) => {
    Modal.warn({
      title: "你確定要作廢這筆資料？",
      okText: "是",
      onOk() {
        dispatch(fetchDeleteReturnOrder(orderId));
      },
      maskClosable: true,
    });
  };

  const columns: ColumnsType<ReturnOrderDetail> = [
    {
      title: "訂單編號",
      dataIndex: "orderNumber",
    },
    {
      title: "出庫倉庫",
      dataIndex: "sourceWarehouseName",
    },
    {
      title: "入庫倉庫",
      dataIndex: "destWarehouseName",
    },
    {
      title: "審核狀態",
      dataIndex: "approvalStatus",
      render: (data: number) => ReviewStateByPositionLabel[data],
    },
    {
      title: "訂單狀態",
      dataIndex: "status",
      render: (data: OrderStatus, values) => <div>{OrderStatusLabel[data]}</div>,
    },
    {
      title: "預期拋單日期",
      dataIndex: "estDeployedDate",
      sorter: true,
    },
    {
      title: "實際出貨時間",
      dataIndex: "shippingAt",
      sorter: true,
    },
    {
      title: "出貨商品總數",
      dataIndex: "totalQty",
      sorter: true,
    },
    {
      title: "總成本",
      dataIndex: "totalCost",
      sorter: true,
    },
    {
      title: "建立人員",
      dataIndex: "staffName",
    },
    {
      title: "建立時間",
      dataIndex: "createdAt",
    },
    {
      title: "",
      dataIndex: "id",
      fixed: "right",
      render: (id: number, record: ReturnOrderDetail) => {
        return record.destroyedAt || !record.canEdit ? (
          <Link to={`/return-inventory/edit/${id}`} target="_blank">
            檢視
          </Link>
        ) : (
          <Link to={`/return-inventory/edit/${id}`} target="_blank">
            編輯
          </Link>
        );
      },
    },
    {
      title: "",
      dataIndex: "delete",
      fixed: "right",
      render: (_, record: ReturnOrderDetail) => {
        return (
          <Button
            type="link"
            onClick={() => {
              deleteOrder(record.id);
            }}
            disabled={record.destroyedAt !== null}
          >
            作廢
          </Button>
        );
      },
    },
  ];

  function checkDisabledOrder(record: ReturnOrderDetail) {
    return {
      className: record.destroyedAt ? "ur-disabled-text" : "",
    };
  }

  return (
    <Table
      loading={isFetching}
      columns={columns}
      dataSource={returnOrderList.results}
      pagination={{
        pageSize: returnOrderListFilter.limit,
        current: page,
        total: returnOrderList.count || 0,
        showSizeChanger: false,
        disabled: isFetching,
        onChange: fetchPageChange,
      }}
      scroll={{ x: "max-content" }}
      rowKey={(record) => record.id}
      onRow={checkDisabledOrder}
    />
  );
}
