/* eslint-disable @typescript-eslint/no-unused-vars */
import { ExclamationCircleOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { PurchaseLines, VerifyDescription, VerifyDescriptionEN } from "@api/utils/normalizeWarehouse";
import {
  clearPurchaseVpc,
  fetchDeletePurchaseLine,
  fetchDeployToLMS,
  fetchPurchaseLines,
  warehouseState,
} from "@redux/warehouseSlice";
import moment from "moment";
import { Button, Modal, Row, Select, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import IntoWarehouseEditProduct from "./IntoWarehouseEditProduct";

const ProductWrapper = styled.div`
  padding: 15px 22px;
`;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div`
  margin-top: 15px;
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

export default function WarehouseProduct() {
  const { warehouseId } = useParams();

  const dispatch = useDispatch();
  const { purchaseLinesResult, purchaseLineRefresh, purchaseInfo } = useSelector(warehouseState);

  const [openEditProduct, setOpenEditProduct] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState<number>(1);
  const [purchaseLine, setPurchaseLine] = useState<PurchaseLines>();

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(
      fetchPurchaseLines({
        purchaseId: parseInt(warehouseId, 10),
        limit: pageSize,
        page: value,
      }),
    );
  };

  const openEditPopup = (value: PurchaseLines) => {
    setOpenEditProduct(true);
    setPurchaseLine(value);
  };

  const closePopup = () => {
    setOpenEditProduct(false);
    setPurchaseLine(undefined);
    dispatch(clearPurchaseVpc());
  };

  const onFetchDeletePurchaseLine = (id: number) => {
    Modal.confirm({
      title: "確定要刪除進倉商品？",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(fetchDeletePurchaseLine(id));
      },
    });
  };

  const columns = [
    {
      key: "id",
      dataIndex: "id",
      title: "ID",
    },
    {
      key: "sku",
      dataIndex: "sku",
      title: "SKU",
    },
    {
      key: "productName",
      dataIndex: "productName",
      title: "商品名稱",
    },
    {
      key: "contractMode",
      dataIndex: "contractMode",
      title: "合作模式",
    },
    {
      key: "purchasePrice",
      dataIndex: "purchasePrice",
      title: "進價",
    },
    {
      key: "requestQty",
      dataIndex: "requestQty",
      title: "申請進貨數量(件)",
    },
    {
      key: "arrivedQty",
      dataIndex: "arrivedQty",
      title: "實際進貨總量(件)",
    },
    {
      key: "stockinDate",
      dataIndex: "stockinDate",
      title: "入庫時間",
    },
    {
      key: "expiredDate",
      dataIndex: "expiredDate",
      title: "有效期限",
    },
    {
      key: "canAcceptExpirationDate",
      dataIndex: "canAcceptExpirationDate",
      title: "允收日",
    },
    // {
    //   key: "verifyDescription",
    //   dataIndex: "verifyDescription",
    //   title: "入庫狀態",
    // },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      render: (value: any, others: PurchaseLines) => (
        <Row justify="end" align="middle" wrap={false}>
          <Button
            type="link"
            disabled={
              others.verifyDescription === VerifyDescription.WAREHOUSING_COMPLETED ||
              others.verifyDescription.replace(/[\s]+/g, "").toLowerCase() === VerifyDescriptionEN.WAREHOUSING_COMPLETED
              // 倉庫有時回傳中文有時候英文，所以兩個都比對
            }
            onClick={() => openEditPopup(others)}
          >
            編輯
          </Button>
          <Button
            type="link"
            disabled={purchaseInfo && purchaseInfo?.matoLink?.length > 0}
            onClick={() => onFetchDeletePurchaseLine(others.id)}
          >
            刪除
          </Button>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    if (purchaseLineRefresh) {
      setPage(1);
      dispatch(
        fetchPurchaseLines({
          purchaseId: parseInt(warehouseId, 10),
          limit: pageSize,
          page: 1,
        }),
      );
    }
  }, [dispatch, warehouseId, pageSize, purchaseLineRefresh]);

  const uploadToLMS = () => {
    const format = "h:mm A";
    const dateFormat = "YYYY-MM-DD HH:mm";
    const disableTimeStart = moment("12:00", format);
    const disableTimeEnd = moment("13:00", format);
    const now = moment();
    const disableDateStart = moment("2022-06-30 15:00", dateFormat);
    const disableDateEnd = moment("2022-07-01 10:00", dateFormat);

    if (now.isBetween(disableDateStart, disableDateEnd)) {
      message.error(`目前倉庫維護中，請於${disableDateEnd.format(dateFormat)}後再嘗試`);
    } else if (now.isBetween(disableTimeStart, disableTimeEnd)) {
      message.error("目前倉庫維護中，請於 13:00 後再嘗試");
    } else {
      dispatch(fetchDeployToLMS(purchaseInfo!.id));
    }
  };

  return (
    <ProductWrapper>
      <Row align="middle" justify="space-between">
        <Row align="middle">
          <CustomButton
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            disabled={purchaseInfo && (purchaseInfo?.matoLink?.length > 0 || !!purchaseInfo.destroyedAt)}
            onClick={() => setOpenEditProduct(true)}
          >
            新增商品
          </CustomButton>
          <Button
            disabled={
              purchaseLinesResult.count === 0 ||
              (purchaseInfo && purchaseInfo?.matoLink?.length > 0) ||
              purchaseInfo?.destroyedAt !== null
            }
            onClick={uploadToLMS}
          >
            上傳至LMS系統
          </Button>
        </Row>
        <Row align="middle">
          <PageText>{`總共${purchaseLinesResult.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageSize(parseInt(value as string, 10))}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <TableWrapper>
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={purchaseLinesResult.results.data}
          pagination={{
            pageSize,
            current: page,
            showSizeChanger: false,
            total: purchaseLinesResult.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
      {openEditProduct && <IntoWarehouseEditProduct purchaseLine={purchaseLine} close={closePopup} />}
    </ProductWrapper>
  );
}
